<template>

  <div class="row">
    <nav>
      <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-education-tab" data-bs-toggle="tab" data-bs-target="#nav-education"
                type="button" role="tab" aria-controls="nav-education" aria-selected="true"><i class="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp; Образование
        </button>
        <button class="nav-link" id="nav-qualification-tab" data-bs-toggle="tab" data-bs-target="#nav-qualification"
                type="button" role="tab" aria-controls="nav-qualification" aria-selected="false"><i class="fa fa-line-chart" aria-hidden="true"></i>&nbsp; Повышение квалификации
        </button>

        <button class="nav-link" id="nav-professional-certification-tab" data-bs-toggle="tab" data-bs-target="#nav-professional-certification"
                type="button" role="tab" aria-controls="nav-professional-certification" aria-selected="false"><i class="fa fa-line-chart" aria-hidden="true"></i>&nbsp; Профессиональная сертификация
        </button>

        <button class="nav-link" id="nav-education-document-tab" data-bs-toggle="tab" data-bs-target="#nav-education-document"
                type="button" role="tab" aria-controls="nav-education-document" aria-selected="false"><i class="fa fa-file-text" aria-hidden="true"></i>&nbsp; Документ
        </button>

        <button class="nav-link" id="nav-science-degree-tab" data-bs-toggle="tab" data-bs-target="#nav-science-degree"
                type="button" role="tab" aria-controls="nav-science-degree" aria-selected="false"><i class="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp; Научная степень
        </button>
      </div>
    </nav>

    <div class="form-block">


        <div class="tab-content" id="nav-tabContent">

          <div class="tab-pane fade show active" id="nav-education" role="tabpanel"
               aria-labelledby="nav-education-tab">
            <EducationForm/>
          </div>

          <div class="tab-pane fade" id="nav-qualification" role="tabpanel"
               aria-labelledby="nav-qualification-tab">
            <QualificationForm/>
          </div>

          <div class="tab-pane fade" id="nav-professional-certification" role="tabpanel"
               aria-labelledby="nav-professional-certification-tab">
            <ProfessionalCertificationForm/>
          </div>

          <div class="tab-pane fade" id="nav-education-document" role="tabpanel"
               aria-labelledby="nav-education-document-tab">
            <EducationFileForm/>
          </div>

          <div class="tab-pane fade" id="nav-science-degree" role="tabpanel"
               aria-labelledby="nav-science-degree-tab">
            <ScienceDegreeForm/>
          </div>


        </div>

    </div>
  </div>
</template>

<script>
import EducationForm from '@/components/user-personal/education/EducationForm.vue'
import QualificationForm from '@/components/user-personal/education/QualificationForm.vue'
import ProfessionalCertificationForm from '@/components/user-personal/education/ProfessionalCertificationForm.vue'
import EducationFileForm from '@/components/user-personal/education/EducationFileForm.vue'
import ScienceDegreeForm from '@/components/user-personal/ScienceDegreeForm.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Education',
  components: {
    EducationForm,
    QualificationForm,
    ProfessionalCertificationForm,
    EducationFileForm,
    ScienceDegreeForm
  },
  methods: {

  },
  computed: {

  },
  created() {

  }
}
</script>