<template>

  <div class="job-form mt-4">
    <h4 class="text-center">Научная деятельность</h4>

    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Scopus Author ID</label>
      <div class="col-md-9">
        <input type="number" class="form-control" v-model="scientificActivity.scopus_author_id">
      </div>
    </div>


    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Research ID Web of Science</label>
      <div class="col-md-9">
        <input type="number" class="form-control" v-model="scientificActivity.research_id_web_of_science">
      </div>
    </div>


    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Членство в академиях наук/профессиональных сообществах</label>
      <div class="col-md-9">
        <input type="text" class="form-control" v-model="scientificActivity.membership_academies">
      </div>
    </div>


    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Наличие опыта в рецензировании/экспертизы научных проектов</label>
      <div class="col-md-9">
        <input type="checkbox" id="checkbox"
               :checked="1===+scientificActivity.experience_review"
               @change="changeExperienceReview($event)"/>
      </div>
    </div>


    <div v-if="+scientificActivity.experience_review" class="my-2">
      <div class="form-group d-flex flex-row-reverse">
        <button @click="addExperienceReview" type="button" class="btn btn-secondary">
          <i class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>


      <div class="my-3">
        <div class="form-row mb-3" v-for="(eR, eRIndex) in experienceReview" :key="eRIndex">
          <div class="d-flex flex-row-reverse">
            <button @click="deleteExperienceReview(eRIndex)" type="button" class="btn btn-light">
              <i class="fas fa-times"></i>
            </button>
          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Наименование организации</label>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="eR.name">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Страна</label>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="eR.country">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Ссылка на сайт организации</label>
            <div class="col-md-9">
              <input type="text" class="form-control" v-model="eR.link_website">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Год начала</label>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="eR.start_year">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Год окончания</label>
            <div class="col-md-9">
              <input type="number" class="form-control" v-model="eR.end_year">
            </div>
          </div>

        </div>
      </div>


    </div>


    <div class="row my-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-pill text-white btn-block btn-primary" @click="saveScientificActivity">
          Сохранить
        </button>
      </div>
    </div>
  </div>


</template>


<script>
import {mapActions} from "vuex"


export default {
  name: "ScientificActivity",
  data() {
    return {
      scientificActivity: {
        scopus_author_id: null,
        research_id_web_of_science: null,
        membership_academies: '',
        experience_review: false
      },
      experienceReview: [],
    }
  },
  methods: {
    ...mapActions('scientificActivity', [
      'GET_SCIENTIFIC_ACTIVITY', 'POST_SCIENTIFIC_ACTIVITY', 'PUT_SCIENTIFIC_ACTIVITY',
      'GET_EXPERIENCE_REVIEW', 'POST_EXPERIENCE_REVIEW', 'PUT_EXPERIENCE_REVIEW', 'DELETE_EXPERIENCE_REVIEW'
    ]),
    addExperienceReview() {
      this.experienceReview.push({
        name: '',
        country: '',
        link_website: '',
        start_year: null,
        end_year: null
      })
    },
    async deleteExperienceReview(idx) {
      if (this.experienceReview[idx].id) {
        const res = await this.DELETE_EXPERIENCE_REVIEW(this.experienceReview[idx].id)
        if (!res) {
          return
        }
      }
      this.experienceReview.splice(idx, 1)
      this.$message({title: 'Удалено'})
    },
    changeExperienceReview(e) {
      this.scientificActivity.experience_review = e.target.checked ? 1 : 0
    },
    async saveScientificActivity() {
      if (this.scientificActivity.id) {
        await this.PUT_SCIENTIFIC_ACTIVITY(this.scientificActivity)
      } else {
        await this.POST_SCIENTIFIC_ACTIVITY(this.scientificActivity)
      }

      const postForm = this.experienceReview.filter(i => !i.id)
      const putForm = this.experienceReview.filter(i => !!i.id)

      if (postForm.length) {
        await this.POST_EXPERIENCE_REVIEW(postForm)
      }

      if (putForm.length) {
        await this.PUT_EXPERIENCE_REVIEW(putForm)
      }

      const scientificActivity = await this.GET_SCIENTIFIC_ACTIVITY()
      if (scientificActivity) {
        this.scientificActivity = scientificActivity
      }

      this.experienceReview = await this.GET_EXPERIENCE_REVIEW()
      this.$message({title: 'Сохранено'})
    }
  },
  async mounted() {
    const scientificActivity = await this.GET_SCIENTIFIC_ACTIVITY()
    if (scientificActivity) {
      this.scientificActivity = scientificActivity
    }

    this.experienceReview = await this.GET_EXPERIENCE_REVIEW()
  }
}

</script>

<style scoped>

</style>
