<template>

  <div class="row">
    <nav>
      <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-myresearchworks-tab" data-bs-toggle="tab"
                data-bs-target="#nav-myresearchworks"
                type="button" role="tab" aria-controls="nav-myresearchworks" aria-selected="false">
          <i class="fa fa-graduation-cap me-2" aria-hidden="true"></i> Мои публикации
        </button>
        <button class="nav-link" id="nav-searchresearchworks-tab" data-bs-toggle="tab"
                data-bs-target="#nav-searchresearchworks"
                type="button" role="tab" aria-controls="nav-searchresearchworks" aria-selected="true">
          <i class="fa fa-search me-2" aria-hidden="false"></i> Поиск публикации в Scopus
        </button>

        <button class="nav-link" id="nav-ScientificActivity-tab" data-bs-toggle="tab"
                data-bs-target="#nav-ScientificActivity"
                type="button" role="tab" aria-controls="nav-ScientificActivity" aria-selected="true">
          <i class="fa fa-book me-2" aria-hidden="false"></i> Научная деятельность
        </button>
      </div>
    </nav>


    <div class="tab-content" id="nav-tabContent">

      <div class="tab-pane fade show active" id="nav-myresearchworks" role="tabpanel"
           aria-labelledby="nav-myresearchworks-tab">
        <MyResearchWorks/>
      </div>
      <div class="tab-pane fade" id="nav-searchresearchworks" role="tabpanel"
           aria-labelledby="nav-searchresearchworks-tab">
        <SearchResearchWorks/>
      </div>
      <div class="tab-pane fade" id="nav-ScientificActivity" role="tabpanel"
           aria-labelledby="nav-ScientificActivity-tab">
        <ScientificActivity/>
      </div>

    </div>


  </div>
</template>

<script>
  import MyResearchWorks from '@/components/user-personal/research-work/MyResearchWorks.vue'
  import SearchResearchWorks from '@/components/user-personal/research-work/SearchResearchWorks.vue'
  import ScientificActivity from '@/components/user-personal/research-work/ScientificActivity.vue'

  export default {
    name: 'ResearchWork',
    components: {
      MyResearchWorks,
      SearchResearchWorks,
      ScientificActivity
    },
  }
</script>
