<template>

  <div class="my-4">
    <h4 class="text-center">Научная степень</h4>

    <div class="row mt-4">
      <label class="col-md-3 col-form-label">Научное звание</label>
      <div class="col-md-9">
        <select class="form-control form-select" v-model="scienceRankId">
          <option :value="0" hidden>Выберите</option>
          <option v-for="(item, index) in scienceRank" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-4">
      <label class="col-md-3 col-form-label">Научная степень</label>
      <div class="col-md-9">
        <select class="form-control form-select" v-model="scienceDegreeId">
          <option :value="0" hidden>Выберите</option>
          <option v-for="(item, index) in scienceDegree" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="scienceDegreeId" class="row mt-4">
      <label class="col-md-3 col-form-label">Подтип научной степени</label>
      <div class="col-md-9">
        <select class="form-control form-select" v-model="scienceDegreeChildId">
          <option :value="0" hidden>Выберите</option>
          <option v-for="(item, index) in scienceDegreeChildByDegreeId" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>


    <div class="my-4 text-center">
      <Button icon="pi pi-save" label="Сохранить" :loading="loadingSaveDegree" :disabled="!scienceDegreeId"
              @click="submitScienceDegree"/>
    </div>

  </div>


</template>

<script>
  import {mapActions, mapState} from "vuex";
  import httpClient from "@/services/http.service"

  export default {
    name: "ScienceDegreeForm",
    data() {
      return {
        scienceDegree: [],
        scienceDegreeChild: [],
        scienceRank: [],
        scienceDegreeId: 0,
        scienceDegreeChildId: 0,
        scienceRankId: 0,
        loadingSaveDegree: false,
      }
    },
    computed: {
      ...mapState('personal', ['form']),
      scienceDegreeChildByDegreeId() {
        return this.scienceDegreeChild.filter(i => i.science_degree_id == this.scienceDegreeId)
      }
    },
    watch: {
      scienceDegreeId() {
        this.scienceDegreeChildId = 0
      }
    },
    methods: {
      ...mapActions(['GET_PPS_DATA']),
      async GET_SCIENCE_DEGREE() {
        try {
          const {status, data} = await httpClient.get(`questionnaire/pps-data/get-science-degree`)
          if (status === 200) {
            this.scienceDegree = data
          }
        } catch (e) {
          this.showError(e)
        }
      },
      async GET_SCIENCE_RANK() {
        try {
          const {status, data} = await httpClient.get(`questionnaire/pps-data/get-science-rank`)
          if (status === 200) {
            console.log('GET_SCIENCE_RANK', data)
            this.scienceRank = data
          }
        } catch (e) {
          this.showError(e)
        }
      },
      async GET_SCIENCE_DEGREE_CHILD() {
        try {
          const {status, data} = await httpClient.get(`questionnaire/pps-data/get-science-degree-child`)
          if (status === 200) {
            this.scienceDegreeChild = data
          }
        } catch (e) {
          this.showError(e)
        }
      },
      async PUT_SCIENCE_DEGREE_DATA() {
        try {
          await httpClient.put(`questionnaire/pps-data/choose-science-degree`, {
            science_degree_id: this.scienceDegreeId,
            science_degree_child_id: this.scienceDegreeChildId || null,
            science_rank_id: this.scienceRankId
          })
          return true
        } catch (e) {
          this.showError(e)
        }
        return false
      },
      async submitScienceDegree() {
        this.loadingSaveDegree = true
        let data = await this.PUT_SCIENCE_DEGREE_DATA()
        if (data) {
          await this.GET_PPS_DATA()
          this.setupDegree()
          this.$message({title: 'Сохранено'})
        }
        this.loadingSaveDegree = false
      },
      showError(e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      },
      setupDegree() {
        this.scienceDegreeId = this.form.science_degree_id || 0
        this.scienceDegreeChildId = this.form.science_degree_child_id || 0
        this.scienceRankId = this.form.science_rank_id || 0
      },
    },
    async mounted() {
      await this.GET_SCIENCE_DEGREE()
      await this.GET_SCIENCE_DEGREE_CHILD()
      await this.GET_SCIENCE_RANK()
      this.setupDegree()
    }
  }

</script>

<style scoped>

</style>
