<template>
    <div class="row clearfix mt-4">
        <h4 style="text-align: center">Импорт данных с HeadHunter</h4>
        <div class="mt-4" v-if="isClickAuthHH">
            <p>1. Авторизуйтесь через hh.ru</p>
            <a href='https://hh.ru/oauth/authorize?response_type=code&client_id=VHKD75E50SAGI04IN84ABM50S2CFUHMSE3T1R6ANMH0F12J2H6J6RTJRF5PTQHPT'
               class="btn btn-pill text-white btn-block btn-primary">
                Авторизация
            </a>
        </div>

        <div class="col-md-12 mt-4">
            <p>2. Нажмите на кнопку Импорт</p>
            <button type="button" class="btn btn-pill text-white btn-block btn-primary"
                    @click="headHunterImport">Импорт
            </button>
        </div>
        <div class="resume_list mt-4" v-if="importIsClick">
            <p>3. Выберите резюме</p>
            <div class="row mt-4" v-for="(item,index) in hh.hhData" :key="index">

                <div class="col-md-9">

                    <a :href="item.alternate_url" target="_blank" class="text-decoration-none">
                        {{ item.title }}
                    </a>

                </div>

                <div class="col-md-3">
                    <input class="form-check-input" name="checkResume" type="radio" @change="changeCheckResume(index)">
                </div>
                <hr>

            </div>
            <div class="col-md-12 mt-4">
                <button type="button" class="btn btn-pill text-white btn-block btn-primary"
                        @click="selectResume">Выбрать
                </button>

            </div>
        </div>

        <div v-if="isSelectResume">

            <h4 class="pt-4 pb-2">Вы выбрали
                {{hh.hhData[hh.resumeIndex].last_name}}
                {{hh.hhData[hh.resumeIndex].first_name}}
                {{hh.hhData[hh.resumeIndex].middle_name}}
            </h4>
            <h4 class="pb-2">Резюме: {{hh.hhData[hh.resumeIndex].title}}</h4>

            <div>
                <h5>Образование</h5>
                <div class="card" v-for="(edu, index) in hh.hhData[hh.resumeIndex].education.primary" :key="index">
                    <div class="card-body">
                        <div><strong>Университет:</strong> {{edu.name}}</div>
                        <div><strong>Специальность:</strong> {{edu.result}}</div>
                    </div>
                </div>
            </div>

            <div>
                <h5>Опыт работы</h5>
                <div class="card" v-for="(edu, index) in hh.hhData[hh.resumeIndex].experience" :key="index">
                    <div class="card-body">
                        <div><strong>Компания:</strong> {{edu.company}}</div>
                        <div v-if="edu.position"><strong>Позиция:</strong> {{edu.position}}</div>
                    </div>
                </div>
            </div>
            <div>
                <h3>Пожалуйста перейдите по нужным вкладкам и нажмите кнопку сохранить если данные корректные!</h3>
            </div>
        </div>


    </div>
</template>


<script>

    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: 'HeadHunterForm',
        data() {
            return {
                importIsClick: false,
                isSelectResume: false
            }
        },
        computed: {
            ...mapState('personal', ['headHunterData']),
            ...mapState(['hh']),
            isClickAuthHH() {
                return new URL(location.href).searchParams.get('code') == null
            }
        },
        methods: {

            ...mapActions(["GET_HEADHUNTER_RESUMES", "SELECT_HH_DATA"]),
            ...mapMutations(["SET_HH_RESUME", "SET_HH_DATA_FOR_MODULES"]),
            ...mapMutations('personal', ["SET_HEADHUNTER_TO_PERSONAL_DATA", "SET_HEADHUNTER_RESUME"]),
            ...mapMutations('education', ["SET_HEADHUNTER_TO_EDUCATION_DATA", "SET_HEADHUNTER_RESUME_EDUCATION"]),
            headHunterImport() {
                console.log(new URL(location.href).searchParams.get('code'))
                this.importIsClick = true
                this.GET_HEADHUNTER_RESUMES()
            },
            changeCheckResume(index) {
                this.SET_HH_RESUME(index)
                this.isSelectResume = false

                // this.SET_HEADHUNTER_RESUME(index)
                // this.SET_HEADHUNTER_RESUME_EDUCATION(index)
            },
            selectResume() {
                this.SELECT_HH_DATA()
                this.isSelectResume = true
                // this.SET_HEADHUNTER_TO_PERSONAL_DATA()
                // this.SET_HEADHUNTER_TO_EDUCATION_DATA()
            }

        },
        created() {
        },
        mounted() {
        }


    }

</script>

<style scoped>

</style>