<template>

  <div class=" personal-form mt-4">
    <h4 style="text-align: center">Личные данные</h4>
<!--    <div class="text-center mt-4">-->
<!--      <Avatar :image="'data:image/png;base64,'+photo" size="xlarge" shape="circle"/>-->
<!--      &lt;!&ndash; <img :src="'data:image/png;base64,'+item.photo" class="mr-2" size="xlarge" shape="circle"/> &ndash;&gt;-->
<!--    </div>-->
    <div class="text-center mb-4" v-if="photo">
      <img width="100" class="rounded-3" :src="'data:image/png;base64,'+photo"/>
    </div>


    <div class="form-group row mt-4">
      <label for="lastname" class="col-md-3 col-form-label">Фамилия</label>
      <div class="col-md-9">
        <input id="lastname" type="text" class="form-control" placeholder="Фамилия" v-model.trim="lastName">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="firstname" class="col-md-3 col-form-label">Имя</label>
      <div class="col-md-9">
        <input id="firstname" type="text" class="form-control" placeholder="Имя" v-model.trim="firstName">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="middlename" class="col-md-3 col-form-label">Отчество</label>
      <div class="col-md-9">
        <input id="middlename" type="text" class="form-control" placeholder="Отчество" v-model.trim="middleName">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="birthplace" class="col-md-3 col-form-label">Место рождения</label>
      <div class="col-md-9">
        <input id="birthplace" type="text" class="form-control" placeholder="Место рождения"
               v-model="birthPlace">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="birthdate" class="col-md-3 col-form-label">Дата рождения</label>
      <div class="col-md-9">
        <input id="birthdate" type="date" class="form-control" placeholder="Дата рождения" v-model="birthDate">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="phone" class="col-md-3 col-form-label">Телефон</label>
      <div class="col-md-9">
        <input id="phone" type="tel" class="form-control" placeholder="Телефон" v-model="phoneNumber">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="home_phone" class="col-md-3 col-form-label">Домашний телефон</label>
      <div class="col-md-9">
        <input id="home_phone" type="tel" class="form-control" placeholder="Домашний телефон"
               v-model="homePhoneNumber">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Пол</label>
      <div class="col-md-9">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="female" value="2"
                 v-model="sex">
          <label class="form-check-label" for="female">Женский</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="male" value="1"
                 v-model="sex">
          <label class="form-check-label" for="male">Мужской</label>
        </div>

      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="nationality" class="col-md-3 col-form-label">Национальность</label>
      <div class="col-md-9">
        <select class="form-control form-select" id="nationality" v-model="nationality">
          <option v-for="(item, index) in [{id: 0, name_ru: 'Выберите национальность'}, ...allNationalities]"
                  :value="item.id" :key="index">{{ item.name_ru }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="marital_status" class="col-md-3 col-form-label">Семейное положение</label>
      <div class="col-md-9">
        <select class="form-control form-select" id="marital_status" v-model="maritalStatus">
          <option v-for="(item, index) in [{id: 0, name: 'Выберите семейное положение'}, ...allMaritalStatuses]"
                  :value="item.id" :key="index">{{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="disability_group" class="col-md-3 col-form-label">Группа инвалидности</label>
      <div class="col-md-9">
        <select class="form-control form-select" id="disability_group">
          <option selected>0</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
        </select>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Отношение к воинской обязанности</label>
      <div class="col-md-9">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="militaryStatus" id="militaryStatusYes" value="1"
                 v-model="militaryStatus">
          <label class="form-check-label" for="militaryStatusYes">Да</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="militaryStatus" id="militaryStatusNo" value="0"
                 v-model="militaryStatus">
          <label class="form-check-label" for="militaryStatusNo">Нет</label>
        </div>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Отметки о судимости</label>
      <div class="col-md-9">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="convictionStatus" id="convictionStatusYes"
                 value="1"
                 v-model="convictionStatus">
          <label class="form-check-label" for="convictionStatusYes">Да</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="convictionStatus" id="convictionStatusNo"
                 value="0"
                 v-model="convictionStatus">
          <label class="form-check-label" for="convictionStatusNo">Нет</label>
        </div>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Готовность к выездам в служебные командировки</label>
      <div class="col-md-9">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="businessTripStatus" id="businessTripStatusYes"
                 value="1"
                 v-model="businessTripStatus">
          <label class="form-check-label" for="businessTripStatusYes">Да</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="businessTripStatus" id="businessTripStatusNo"
                 value="0"
                 v-model="businessTripStatus">
          <label class="form-check-label" for="businessTripStatusNo">Нет</label>
        </div>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Являетесь ли вы пенсионером?</label>
      <div class="col-md-9">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="retireeStatus" id="retireeStatusYes" value="1"
                 v-model="retireeStatus">
          <label class="form-check-label" for="retireeStatusYes">Да</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="retireeStatus" id="retireeStatusNo" value="0"
                 v-model="retireeStatus" checked>
          <label class="form-check-label" for="retireeStatusNo">Нет</label>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-4 text-center">
      <p style="color: red"> <b>После заполнения личных данных перейдите во вкладку "Документ" и нажмите на кнопку сохранить!</b></p>
    </div>


  </div>

</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: 'FormAnketa',
  data: () => ({
    startDate: '1920-01-01',
    ppsDataPhoto: {},
    loadingSave: false
  }),
  computed: {
    ...mapState('personal', ['form', 'genders', 'headHunterData']),
    ...mapGetters('personal', ['allNationalities', 'allGenders', 'allMaritalStatuses']),

    firstName: {
      get() {
        return this.form.firstname
      },
      set(value) {
        this.updateFirstName(value)
      }
    },
    lastName: {
      get() {
        return this.form.lastname
      },
      set(value) {
        this.updateLastName(value)
      }
    },
    middleName: {
      get() {
        return this.form.middlename
      },
      set(value) {
        this.updateMiddleName(value)
      }
    },
    birthPlace: {
      get() {
        return this.form.birth_place
      },
      set(value) {
        this.updateBirthPlace(value)
      }
    },
    birthDate: {
      get() {
        return this.convertTimestampToDate(this.form.birth_date)
      },
      set(value) {
        if (new Date(value) > new Date(this.startDate)) {
          this.updateBirthDate(value)
        }
      }
    },
    phoneNumber: {
      get() {
        return this.form.phone_number
      },
      set(value) {
        this.updatePhoneNumber(value)
      }
    },
    homePhoneNumber: {
      get() {
        return this.form.home_phone_number
      },
      set(value) {
        this.updateHomePhoneNumber(value)
      }
    },
    sex: {
      get() {
        return this.form.sex
      },
      set(value) {
        this.updateSex(value)
      }
    },
    nationality: {
      get() {
        return this.form.nationality
      },
      set(value) {
        this.updateNationality(value)
      }
    },
    maritalStatus: {
      get() {
        return this.form.marital_status
      },
      set(value) {
        this.updateMaritalStatus(value)
      }
    },
    retireeStatus: {
      get() {
        return this.form.retiree_status
      },
      set(value) {
        this.updateRetireeStatus(value)
      }
    },
    disabilityGroup: {
      get() {
        return this.form.disability_group
      },
      set(value) {
        this.updateDisabilityGroup(value)
      }
    },
    militaryStatus: {
      get() {
        return this.form.military_status
      },
      set(value) {
        this.updateMilitaryStatus(value)
      }
    },
    convictionStatus: {
      get() {
        return this.form.conviction_status
      },
      set(value) {
        this.updateConvictionStatus(value)
      }
    },
    businessTripStatus: {
      get() {
        return this.form.business_trip_status
      },
      set(value) {
        this.updateBusinessTripStatus(value)
      }
    },
    photo: {
      get() {
        return this.form.photo
      },
    },
  },
  methods: {
    ...mapActions('personal', ["getNationalities", "getGenders", "getMaritalStatuses", "GET_HEADHUNTER_RESUMES"]),
    ...mapMutations('personal', ["updateFirstName", "updateLastName", "updateMiddleName", "updateBirthPlace", "updateBirthDate",
      "updatePhoneNumber", "updateHomePhoneNumber", "updateSex", "updateNationality", "updateMaritalStatus", "updateRetireeStatus", "updateDisabilityGroup",
      "updateMilitaryStatus", "updateConvictionStatus", "updateBusinessTripStatus"]),
    headHunterImport() {
      this.GET_HEADHUNTER_RESUMES()
    },
    convertTimestampToDate(timestamp) {
      let date = new Date(timestamp * 1000)
      let month = date.getMonth() + 1
      let day = date.getDate()
      let monthZero = month < 10 ? '0' : ''
      let dayZero = day < 10 ? '0' : ''
      return date.getFullYear() + '-' + monthZero + month + '-' + dayZero + day
    },
    async getTest() {
      try {
        const {
          status,
          data
        } = await httpClient.get('questionnaire/pps-data/test' + '?access-token=' + getCookie('ACCESS_TOKEN'));
        if (status === 200) {
          console.log(data, '=====================test pps data-------------------');
          return data
        }
      } catch (e) {
        console.log(e.response);
        return {
          errors: e.response.data.errors
        }
      }
    },


  },
  async created() {

    // this.getUserInfo();
    this.getNationalities();
    this.getGenders();
    this.getMaritalStatuses();
    await this.getTest();
  },
  mounted() {
  }


}

</script>

<style scoped>

</style>