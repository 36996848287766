<template>
  
  <div class="row">
    <nav>
      <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">

        <button class="nav-link active" id="nav-headhunter-tab" data-bs-toggle="tab" data-bs-target="#nav-headhunter"
                type="button" role="tab" aria-controls="nav-headhunter" aria-selected="true"><i class="fa fa-download" aria-hidden="true"></i>&nbsp; Импорт HeadHunter
        </button>

        <button class="nav-link" id="nav-personal-tab" data-bs-toggle="tab" data-bs-target="#nav-personal"
                type="button" role="tab" aria-controls="nav-personal" aria-selected="false"><i class="fa fa-user" aria-hidden="true"></i>&nbsp; Личные данные
        </button>
        <button class="nav-link" id="nav-document-tab" data-bs-toggle="tab" data-bs-target="#nav-document"
                type="button" role="tab" aria-controls="nav-document" aria-selected="false"><i class="fa fa-file-text" aria-hidden="true"></i>&nbsp; Документ
        </button>
        <button class="nav-link" id="nav-address-tab" data-bs-toggle="tab" data-bs-target="#nav-address"
                type="button" role="tab" aria-controls="nav-address" aria-selected="false"><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; Адрес
        </button>
        <button class="nav-link" id="nav-children-tab" data-bs-toggle="tab" data-bs-target="#nav-children"
                type="button" role="tab" aria-controls="nav-children" aria-selected="false"><i class="fas fa-child" aria-hidden="true"></i>&nbsp; Дети
        </button>

        <button class="nav-link" id="nav-photo-tab" data-bs-toggle="tab" data-bs-target="#nav-photo"
                type="button" role="tab" aria-controls="nav-photo" aria-selected="false"><i class="fa fa-picture-o" aria-hidden="true"></i>&nbsp; Фото
        </button>


      </div>
    </nav>

    <div class="form-block">
      <form v-on:submit.prevent="submitAnketa">

        <div class="tab-content" id="nav-tabContent">


          <div class="tab-pane fade show active" id="nav-headhunter" role="tabpanel"
               aria-labelledby="nav-headhunter-tab">
            <HeadHunterForm/>
          </div>

          <div class="tab-pane fade" id="nav-personal" role="tabpanel"
               aria-labelledby="nav-personal-tab">
            <PersonalForm/>
          </div>

          <div class="tab-pane fade" id="nav-document" role="tabpanel"
               aria-labelledby="nav-document-tab">
            <DocumentForm/>
          </div>

          <div class="tab-pane fade" id="nav-address" role="tabpanel"
               aria-labelledby="nav-address-tab">
            <AddressForm/>
          </div>

          <div class="tab-pane fade" id="nav-children" role="tabpanel"
               aria-labelledby="nav-children-tab">
            <ChildrenForm/>
          </div>

          <div class="tab-pane fade" id="nav-photo" role="tabpanel"
               aria-labelledby="nav-photo-tab">
            <PhotoForm/>
          </div>

          

        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AddressForm from '@/components/user-personal/questionnaire/AddressForm.vue'
import DocumentForm from '@/components/user-personal/questionnaire/DocumentForm.vue'
import PersonalForm from '@/components/user-personal/questionnaire/PersonalForm.vue'
import HeadHunterForm from '@/components/user-personal/questionnaire/HeadHunterForm.vue'
import ChildrenForm from '@/components/user-personal/questionnaire/ChildrenForm.vue'
import PhotoForm from '@/components/user-personal/questionnaire/PhotoForm.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Questionnaire',
  components: {
    AddressForm,
    DocumentForm,
    PersonalForm,
    HeadHunterForm,
    ChildrenForm,
    PhotoForm
  },
  methods: {
    ...mapActions(["GET_PPS_DATA", "GET_USER_ADDRESS_DATA"]),
  },
  computed: {

  },
  created() {
    this.GET_PPS_DATA()
    this.GET_USER_ADDRESS_DATA()
  }
}
</script>