<template>


<!-- Delete Research Work Modal -->
<div class="modal fade" :id="pageId" tabindex="-1"
     aria-labelledby="deleteResearchWorkModalLabel"
     aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="deleteResearchWorkModalLabel">Удаление модуля</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="col-md-12 mt-4">
        <p>Вы точно хотите удалить?</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              @click="deleteDbAuthorArticles(researchWorkDeleteId)">
        Удалить
      </button>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Отменить
      </button>

    </div>
  </div>
</div>
</div>
<!-- End Modal -->
</template>
<script>

import {mapGetters, mapActions, mapState, mapMutations} from "vuex";

export default {
  name: 'DeleteResearchWorksModal',
  props: ['pageId'],
  computed: {
    ...mapState('researchwork', ['researchWorkDeleteId']),


  },
  methods: {
    ...mapActions('researchwork', ['DELETE_DB_AUTHOR_ARTICLES','GET_DB_AUTHOR_ARTICLES']),
     async deleteDbAuthorArticles(id) {
       await this.DELETE_DB_AUTHOR_ARTICLES(id);
      this.$message({title: 'Удаление', text: 'Успешное удаление'})
       await this.GET_DB_AUTHOR_ARTICLES()
    },
  },
  created() {

  },
  mounted() {
  }
}

</script>

<style scoped>

</style>