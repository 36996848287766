<template>
  <div class="education-form mt-4">
    <h4 style="text-align: center">Образование </h4>
    <p class="under_education_text text-center">перечислить с последнего учебного заведения и все
      предыдущие</p>

    <div class="form-row" v-for="(educationInfo, index) in education_form.educationInfos" :key="index">
      <div class="education-remove-item__button d-flex flex-row-reverse">
        <i class="fa fa-times" @click="deleteRowEducationInfo(index)"></i>
      </div>


      <div class="form-group row mt-4">
        <label for="education_name" class="col-md-3 col-form-label">Учебное заведение</label>
        <div class="col-md-9">
          <input id="education_name" type="text" class="form-control"
                 placeholder="Название университета" :value="educationInfo.name"
                 @input="e=>{changeEducationName(index, e)}">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_address" class="col-md-3 col-form-label">Адрес</label>
        <div class="col-md-9">
          <input id="education_address" type="text" class="form-control" placeholder="Адрес"
                 :value="educationInfo.address" @input="e=>{changeEducationAddress(index, e)}">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_faculty" class="col-md-3 col-form-label">Факультет</label>
        <div class="col-md-9">
          <input id="education_faculty" type="text" class="form-control" placeholder="Факультет"
                 :value="educationInfo.faculty" @input="e=>{changeEducationFaculty(index, e)}">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_specialization" class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" id="language"
                  @input="e=>{changeEducationStudyLevel(index, e)}">
            <option v-for="(item, index) in [{id: 0, name:'Выберите уровень образования'},...educationdiscipline_form.studyLevel]" :value="item.id" :key="index"
                    :selected="item.id == educationInfo.study_level_id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_specialization" class="col-md-3 col-form-label">Специализация</label>
        <div class="col-md-9">
          <input id="education_specialization" type="text" class="form-control" placeholder="Специализация"
                 :value="educationInfo.specialization"
                 @input="e=>{changeEducationSpecialization(index, e)}">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_start_year" class="col-md-3 col-form-label">Год поступления</label>
        <div class="col-md-9">
          <input id="education_start_year" type="text" class="form-control" placeholder="2017"
                 :value="educationInfo.start_year" @input="e=>{changeAdmissionYear(index, e)}">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_end_year" class="col-md-3 col-form-label">Год окончания</label>
        <div class="col-md-9">
          <input id="education_end_year" type="text" class="form-control" placeholder="2021"
                 :value="educationInfo.end_year" @input="e=>{changeGraduationYear(index, e)}">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="language" class="col-md-3">Язык обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" id="language"
                  @input="e=>{changeEducationLanguage(index, e)}">
            <option v-for="(item, index) in allLanguages" :value="item.id" :key="index"
                    :selected="item.id == educationInfo.lang_id">
              {{ item.native_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="education_nostrification" class="col-md-3 col-form-label">Имеется ли нострификация диплома?</label>
        <div class="col-md-9">
          <input type="checkbox" id="education_nostrification" :checked="1 == educationInfo.nostrification"
                 @change="e=>{changeEducationNostrification(index, e)}"/>
        </div>
      </div>

      <hr>

    </div>
    <div class="form-group">
      <button @click="addNewRowEducationInfo" type="button" class="btn btn-secondary"><i
          class="fas fa-plus-circle"></i>
        Добавить
      </button>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitEducation()">Сохранить
        </button>
      </div>
    </div>
  </div>


  <FlashMessage group="education"></FlashMessage>


</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import axios from 'axios'

export default {
  name: "EducationForm",
  computed: {
    ...mapState('education', ['education_form', 'languages']),
    ...mapGetters('education', ['allLanguages']),
    ...mapState('educationdiscipline', ['educationdiscipline_form']),
  },
  methods: {
    ...mapActions('education', ['POST_EDUCATION_DATA', 'getLanguages', 'PUT_EDUCATION_DATA', 'GET_EDUCATION_DATA', 'DELETE_EDUCATION_DATA']),
    ...mapMutations('education', ['updateEducationName', 'newRowEducationInfo', 'updateEducationAddress', 'updateEducationFaculty', 'updateEducationNostrification',
      'updateAdmissionYear', 'updateGraduationYear', 'updateEducationSpecialization', 'updateEducationLanguage', 'updateEducationStudyLevel', 'DELETE_ROW_EDUCATION_INFO']),

    ...mapActions('educationdiscipline', ['GET_STUDY_LEVEL_NAMES']),

    deleteRowEducationInfo(index) {
      if (this.education_form.educationInfos[index].id) {
        this.DELETE_EDUCATION_DATA(index);
        console.log('delete ' + index)
      }
      this.DELETE_ROW_EDUCATION_INFO(index);
    },
    addNewRowEducationInfo() {
      this.newRowEducationInfo();
    },
    submitEducation() {
      this.PUT_EDUCATION_DATA().then(res => {
        if (res.success == true) {
          this.GET_EDUCATION_DATA();
          this.$flashMessage.show({
            group: 'education',
            type: 'success',
            title: 'Обновление',
            text: 'Данные успешно обновились',
            time: 3000,
            blockClass: 'custom-block-class'
          })
        } else if (res.success == false) {
          this.$flashMessage.show({
            group: 'education',
            type: 'error',
            title: 'Ошибка при обновлении',
            text: res.errors,
            time: 3000,
            blockClass: 'custom-block-class'
          })
        }
      })


      this.POST_EDUCATION_DATA().then(res => {
        if (res.success == true) {
          this.GET_EDUCATION_DATA();
          this.$flashMessage.show({
            group: 'education',
            type: 'success',
            title: 'Добавление',
            text: 'Данные успешно добавились',
            time: 5000,
            blockClass: 'custom-block-class'
          })
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$flashMessage.show({
            group: 'education',
            type: 'error',
            title: 'Ошибка при добавлении',
            text: errorText,
            time: 10000
          })
        }
      })

    },
    changeEducationName(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateEducationName({index, value: e.target.value})
    },
    changeEducationAddress(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateEducationAddress({index, value: e.target.value})
    },
    changeAdmissionYear(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateAdmissionYear({index, value: e.target.value})
    },
    changeGraduationYear(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateGraduationYear({index, value: e.target.value})
    },
    changeEducationSpecialization(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateEducationSpecialization({index, value: e.target.value})
    },
    changeEducationLanguage(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateEducationLanguage({index, value: e.target.value})
    },
    changeEducationFaculty(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateEducationFaculty({index, value: e.target.value})
    },
    changeEducationStudyLevel(index, e) {
      console.log(index, ', ', e.target.value)
      this.updateEducationStudyLevel({index, value: e.target.value})
    },
    changeEducationNostrification(index, e) {
      console.log(index, ', ', e.target.checked)
      this.updateEducationNostrification({index, value: e.target.checked})
    },
  },
  created() {
    this.GET_EDUCATION_DATA();
    this.getLanguages();
    this.GET_STUDY_LEVEL_NAMES()
    /*
    this.GET_SCOPUS().then(res => {
      console.log(res, 'die');
      for (let i = 0; i < res.data["search-results"].entry.length; i++) {
        if(res.data["search-results"].entry[i]['prism:doi']){
          this.GET_SCOPUS_DOI(res.data["search-results"].entry[i]['prism:doi'])
        }
      }
    })
     */

  }
}

</script>
<style scoped>
.education-remove-item__button {
  color: red;
}

.under_education_text {
  font-size: 15px;
  color: darkgray;
}
</style>

