<template>



    <div class="address-form mt-4">

      <h4 style="text-align: center">Адрес прописки</h4>
      <div class="col-md-9 mt-4">
      <div class="form-group row mt-4">
        <label for="residence_city" class="col-md-3 col-form-label">Город</label>
        <div class="col-md-9">
          <select class="form-control form-select" id="residence_city" v-model="residence_city">
            <option v-for="(item, index) in allCities" :value="item.id" :key="index">{{ item.name }}</option>
          </select>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="residence_street" class="col-md-3 col-form-label">Улица</label>
        <div class="col-md-9">
          <input id="residence_street" type="text" class="form-control" placeholder="Улица" v-model="residence_street">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="residence_home" class="col-md-3 col-form-label">Дом</label>
        <div class="col-md-9">
          <input id="residence_home" type="text" class="form-control" placeholder="Дом" v-model="residence_home">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="residence_apartment" class="col-md-3 col-form-label">Квартира</label>
        <div class="col-md-9">
          <input id="residence_apartment" type="text" class="form-control" placeholder="Квартира"
                 v-model="residence_apartment">
        </div>
      </div>
    </div>
    <div class="col-md-9 mt-4">
      <div class="form-group row mt-4">
        <label for="checkbox" class="col-md-11 col-form-label">Поставьте галочку если адрес прописки совпадает с адресом проживания</label>
        <div class="col-md-1">
          <input type="checkbox" id="checkbox" v-model="equal_checkbox">
        </div>
      </div>
    </div>
    <div class="col-md-9 mt-4">
      <h4 style="text-align: center">Адрес проживания</h4>
      <div class="form-group row mt-4">
        <label for="inhabitation_city" class="col-md-3 col-form-label">Город</label>
        <div class="col-md-9">
          <select class="form-control form-select" id="inhabitation_city" v-model="inhabitation_city">
            <option v-for="(item, index) in allCities" :value="item.id" :key="index">{{ item.name }}</option>
          </select>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="inhabitation_street" class="col-md-3 col-form-label">Улица</label>
        <div class="col-md-9">
          <input id="inhabitation_street" type="text" class="form-control" placeholder="Улица"
                 v-model="inhabitation_street">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="inhabitation_home" class="col-md-3 col-form-label">Дом</label>
        <div class="col-md-9">
          <input id="inhabitation_home" type="text" class="form-control" placeholder="Дом" v-model="inhabitation_home">
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="inhabitation_apartment" class="col-md-3 col-form-label">Квартира</label>
        <div class="col-md-9">
          <input id="inhabitation_apartment" type="text" class="form-control" placeholder="Дом"
                 v-model="inhabitation_apartment">
        </div>
      </div>
    </div>

      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <button class="btn btn-pill text-white btn-block btn-primary" @click="sendUserAddressData">Сохранить</button>
        </div>

      </div>
    </div>

    <FlashMessage group="address"></FlashMessage>


</template>

<script>
import axios from 'axios'
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";

export default {
  name: 'AddressForm',
  computed: {
    ...mapGetters('useraddress', ['allCities', 'allRegions', 'allCountries']),
    ...mapState('useraddress', ['form','equalcheckbox']),
    residence_city: {
      get() {
        return this.form.residence_city
      },
      set(value) {
        this.updateResidenceCity(value)
      }
    },
    residence_street: {
      get() {
        return this.form.residence_street
      },
      set(value) {
        this.updateResidenceStreet(value)
      }
    },
    residence_apartment: {
      get() {
        return this.form.residence_apartment
      },
      set(value) {
        this.updateResidenceApartment(value)
      }
    },
    residence_home: {
      get() {
        return this.form.residence_home
      },
      set(value) {
        this.updateResidenceHome(value)
      }
    },
    inhabitation_city: {
      get() {
        return this.form.inhabitation_city
      },
      set(value) {
        this.updateInhabitionCity(value)
      }
    },
    inhabitation_street: {
      get() {
        return this.form.inhabitation_street
      },
      set(value) {
        this.updateInhabitionStreet(value)
      }
    },
    inhabitation_apartment: {
      get() {
        return this.form.inhabitation_apartment
      },
      set(value) {
        this.updateInhabitionApartment(value)
      }
    },
    inhabitation_home: {
      get() {
        return this.form.inhabitation_home
      },
      set(value) {
        this.updateInhabitionHome(value)
      }
    },
    equal_checkbox: {
      get() {
        return this.equalcheckbox
      },
      set(value) {
        this.updateEqualCheckbox(value)
      }
    },
  },
  methods: {
    ...mapActions('useraddress', ["getCity", "getRegion", "getCountry"]),
    ...mapActions('useraddress',['POST_USER_ADDRESS_DATA', 'PUT_USER_ADDRESS_DATA']),
    ...mapActions(['GET_USER_ADDRESS_DATA']),
    ...mapMutations('useraddress', ['updateCity','updateEqualCheckbox', 'updateRegion', 'updateCountry', 'updateResidenceCity',
      'updateResidenceStreet', 'updateResidenceApartment', 'updateResidenceHome', 'updateInhabitionCity',
      'updateInhabitionStreet', 'updateInhabitionApartment', 'updateInhabitionHome']),
    sendUserAddressData(){
      this.PUT_USER_ADDRESS_DATA().then(res => {
        if (res.success == true) {
          this.GET_USER_ADDRESS_DATA();
          this.$flashMessage.show({
            group: 'address',
            type: 'success',
            title: 'Обновление',
            text: 'Данные адреса успешно обновились',
            time: 3000,
            blockClass: 'custom-block-class'
          })
        }
        else if (res.success == false) {
          this.$flashMessage.show({
            group: 'address',
            type: 'error',
            title: 'Ошибка при обновлении',
            text: res.errors,
            time: 3000,
            blockClass: 'custom-block-class'
          })
        }
      })


      this.POST_USER_ADDRESS_DATA().then(res => {
        if (res.success == true) {
          this.GET_USER_ADDRESS_DATA();
          this.$flashMessage.show({
            group: 'address',
            type: 'success',
            title: 'Добавление',
            text: 'Данные адреса успешно добавились',
            time: 5000,
            blockClass: 'custom-block-class'
          })
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$flashMessage.show({
            group: 'address',
            type: 'error',
            title: 'Ошибка при добавлении',
            text: errorText,
            time: 10000
          })
        }
      })
    }
  },
  created() {
    this.getCity();
    this.getRegion();
    this.getCountry();
  },
  mounted() {

  }
}

</script>

<style scoped>

</style>