<template>
  <div class="mt-4">
    <h4 style="text-align: center">Опыт в составлении отчета по самооценке</h4>

    <div class="form-row mt-4" v-for="(accreditation_experience_info, index) in accreditation_experience" :key="index">
      <div class="accreditation-remove-item__button d-flex flex-row-reverse">
        <i class="fa fa-times" @click="deleteRowAccreditationInfo(index)"></i>
      </div>
      <div class="row">
        <label for="accreditation_type" class="col-md-4 col-form-label">Вид прохождения аккредитации</label>
        <div class="col-md-8">
          <select id="accreditation_type" class="form-control form-select"
                  @input="changeAccreditation(index,'accreditation_type_id', $event)">
            <option v-for="(itemAccreditationType, index) in [{id:0, name:'Выбрать'},...accreditation_types]"
                    :value="itemAccreditationType.id"
                    :key="index"
                    :selected="itemAccreditationType.id == accreditation_experience_info.accreditation_type_id">
              {{ itemAccreditationType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-4">
        <label for="accreditation_agency" class="col-md-4 col-form-label">Аккредитационное агентство</label>
        <div class="col-md-8">
          <select id="accreditation_agency" class="form-control form-select"
                  @input="changeAccreditation(index,'accreditation_agencies_id', $event)">
            <option v-for="(itemAccreditationAgency, index) in [{id:0, name:'Выбрать'},...accreditationAgencies]"
                    :value="itemAccreditationAgency.id"
                    :key="index"
                    :selected="itemAccreditationAgency.id == accreditation_experience_info.accreditation_agencies_id">
              {{ itemAccreditationAgency.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-4">
        <label for="accreditation_role" class="col-md-4 col-form-label">Роль</label>
        <div class="col-md-8">
          <select id="accreditation_role" class="form-control form-select"
                  @input="changeAccreditation(index,'accreditation_role_id', $event)">
            <option v-for="(itemAccreditationRole, index) in [{id:0, name:'Выбрать'},...accreditation_roles]"
                    :value="itemAccreditationRole.id"
                    :key="index"
                    :selected="itemAccreditationRole.id == accreditation_experience_info.accreditation_role_id">
              {{ itemAccreditationRole.name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="accreditation_experience_info.accreditation_type_id == 1" class="mt-4">
        <div class="row">
          <label for="accreditation_op_name" class="col-md-4 col-form-label">Название образовательной программы</label>
          <div class="col-md-8">
            <input id="accreditation_op_name" type="text" class="form-control" placeholder="digital marketing"
                   :value="accreditation_experience_info.education_program_name"
                   @change="changeAccreditation(index, 'education_program_name', $event)">
          </div>
        </div>
      </div>
      <hr>

    </div>
    <div class="form-group">
      <button @click="addRowAccreditationExperience" type="button" class="btn btn-secondary"><i
          class="fas fa-plus-circle"></i>
        Добавить
      </button>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-pill text-white btn-block btn-primary" @click="submitAccreditationExperience">Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Accreditation",
  data() {
    return {}
  },
  computed: {
    ...mapState('accreditationExperience', ['accreditation_types', 'accreditation_roles', 'accreditation_experience', 'accreditationAgencies']),
  },
  methods: {
    ...mapActions('accreditationExperience', ['GET_ACCREDITATION_TYPES', 'GET_ACCREDITATION_ROLES', 'GET_ACCREDITATION_EXPERIENCE', 'DELETE_ACCREDITATION_EXPERIENCE', 'POST_ACCREDITATION_EXPERIENCE', 'PUT_ACCREDITATION_EXPERIENCE',
    'GET_ACCREDITATION_AGENCIES'
    ]),
    ...mapMutations('accreditationExperience', ['SET_ACCREDITATION_EXPERIENCE', 'ADD_ROW_ACCREDITATION_EXPERIENCE', 'CHANGE_ACCREDITATION', 'DELETE_ROW_ACCREDITATION_INFO']),
    addRowAccreditationExperience() {
      this.ADD_ROW_ACCREDITATION_EXPERIENCE();
    },
    changeAccreditation(index, accreditation_data, e) {
      this.CHANGE_ACCREDITATION({index, accreditation_data, value: e.target.value})
    },
    async deleteRowAccreditationInfo(index) {
      if (this.accreditation_experience[index]['id'] != null) {
        await this.DELETE_ACCREDITATION_EXPERIENCE(index)
      }
      this.DELETE_ROW_ACCREDITATION_INFO(index)

      this.$message({title: 'Удалено'})
    },
    submitAccreditationExperience() {
      this.PUT_ACCREDITATION_EXPERIENCE().then(res => {
        if (res == true) {
          this.GET_ACCREDITATION_EXPERIENCE();
          this.$message({title: 'Обновлено'})
        }
      })
      this.POST_ACCREDITATION_EXPERIENCE().then(res => {
        if (res == true) {
          this.GET_ACCREDITATION_EXPERIENCE();
          this.$message({title: 'Добавлено'})
        }
      })
    }
  },
  async mounted() {
    await this.GET_ACCREDITATION_AGENCIES()
    await this.GET_ACCREDITATION_TYPES()
    await this.GET_ACCREDITATION_ROLES()
    await this.GET_ACCREDITATION_EXPERIENCE()
  }
}
</script>

<style scoped>
.accreditation-remove-item__button {
  color: red;
}
</style>
