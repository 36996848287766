<template>
  <div class="rounded vue-avatar-cropper-demo text-center">
    <div class="p-6">
      <div class="relative">
        <div class="absolute bottom-0 left-0 right-0">
          <button class="btn btn-primary" @click="showCropper=true">Выберите фото</button>
        </div>
      </div>
    </div>
<avatar-cropper
        @uploading="handleUploading"
        @uploaded="handleUploaded"
        @completed="handleCompleted"
        @error="handlerError"
        v-model="showCropper"
        :upload-url= getUrl />
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";
import {mapActions} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  components: { AvatarCropper },
  data() {
    return {
      showCropper: false,
      message: "ready",
      user: {
        id: 1,
        nickname: "Nurkhan",
        username: "zhaksylyk.n",
        avatar: "https://avatars0.githubusercontent.com/u/1472352?s=460&v=4"
      }
    };
  },
  methods: {
    ...mapActions(['GET_PPS_DATA']),

    handleUploading(form, xhr) {
      this.message = "uploading...";
    },
    handleUploaded(response) {
      console.log(response)
      this.GET_PPS_DATA()
    },
    handleCompleted(response, form, xhr) {
      this.message = "upload completed.";
    },
    handlerError(message, type, xhr) {
      this.message = "Oops! Something went wrong...";
    }
  },
  computed: {
    getUrl(){
      return "https://back.uib.kz/questionnaire/pps-data/update-photo?access-token="+getCookie('ACCESS_TOKEN');
    }
  }
};
</script>

<style>
.vue-avatar-cropper-demo {
  /*max-width: 18em;*/
  margin: 0 auto;
  width: 225px; height: 300px;
}
.avatar {
  width: 225px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
}
.card-img-overlay {
  display: none;
  transition: all 0.5s;
}
.card-img-overlay button {
  margin-top: 20vh;
}
.card:hover .card-img-overlay {
  display: block;
}
</style>
