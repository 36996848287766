<template>

  <div class="my-3">
    <h4 class="text-center">Научно-исследовательские гранты</h4>

    <div class="my-3">
      <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary m-1"
              @click="openGrantModal(0)"/>
    </div>

    <div class="row border rounded-3 px-1 py-3 my-2"
         v-for="(grant, index) in researchGrants"
         :key="index">

      <div class="col-md-10">
        <h5>{{ grant.job_title }}</h5>
        <div class="my-1">
          {{grant.institution_receiving}}
        </div>
        <div class="my-1">{{ grant.start_date }} - {{ grant.end_date }}</div>
        <div v-if="grant.description_result" class="my-2">
          {{ grant.description_result }}
        </div>
        <div>
          <span v-if="grant.participation_id" class="bg-secondary text-light rounded-3 px-2 py-1 me-2">
            {{ formsOfParticipation.find(i=>i.id==grant.participation_id)?.name }}
          </span>
        </div>
      </div>
      <div class="col-md-1">
        <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                @click="openGrantModal(grant.id)"/>
      </div>
      <div class="col-md-1">
        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                @click="deleteGrant(grant.id)"/>
      </div>

    </div>


    <!-- add or update grant modal -->
    <Dialog :header="grantModel?.id?'Обновление гранта':'Добавление гранта'"
            v-model:visible="displayGrantModal"
            :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="false">
      <div class="form-row my-3">
        <div class="form-group row mt-3">
          <label for="institution_receiving" class="col-md-3 col-form-label">
            Название учреждения получившего грант
          </label>
          <div class="col-md-9">
            <input id="institution_receiving" type="text" class="form-control"
                   placeholder="Название учреждения получившего грант" v-model="grantModel.institution_receiving">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="job_title" class="col-md-3 col-form-label">Название работы</label>
          <div class="col-md-9">
            <input id="job_title" type="text" class="form-control"
                   placeholder="Название работы" v-model="grantModel.job_title">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="participation_id" class="col-md-3">Форма участия</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="participation_id"
                    v-model="grantModel.participation_id">
              <option v-for="(item, index) in formsOfParticipation" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="financing_source_id" class="col-md-3">Источник финансирования</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="financing_source_id"
                    v-model="grantModel.financing_source_id">
              <option v-for="(item, index) in financingSource" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="start_date" class="col-md-3 col-form-label">Дата начала</label>
          <div class="col-md-9">
            <input id="start_date" type="number" class="form-control" placeholder="2019"
                   v-model="grantModel.start_date">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="end_date" class="col-md-3 col-form-label">Дата окончания</label>
          <div class="col-md-9">
            <input id="end_date" type="number" class="form-control" placeholder="2020"
                   v-model="grantModel.end_date">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="description_result" class="col-md-3 col-form-label">Описание и конечные результаты</label>
          <div class="col-md-9">
            <textarea id="description_result" type="text" class="form-control" rows="3"
                      placeholder="Описание и конечные результаты"
                      v-model="grantModel.description_result"></textarea>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeGrantModal" class="p-button-text"/>
        <Button :label="grantModel?.id?'Обновить':'Создать'" icon="pi pi-check"
                :loading="loadingSave"
                autofocus
                @click="saveGrants"/>
      </template>
    </Dialog>
    <!-- end add or update grant modal -->
  </div>
</template>

<script>

  import {mapActions, mapState} from 'vuex'

  export default {
    name: "ResearchGrantsForm",
    data() {
      return {
        loading: true,
        researchGrants: [],
        grantModel: {},
        displayGrantModal: false,
        loadingSave: false
      }
    },
    computed: {
      ...mapState('teacherGrants', ['formsOfParticipation', 'financingSource']),
    },
    methods: {
      ...mapActions('teacherGrants', [
        'GET_FORMS_OF_PARTICIPATION',
        'GET_FINANCING_SOURCE',
        'GET_RESEARCH_GRANTS',
        'POST_RESEARCH_GRANT',
        'PUT_RESEARCH_GRANT',
        'DELETE_RESEARCH_GRANT'
      ]),
      openGrantModal(id = 0) {
        if (id) {
          this.grantModel = {...this.researchGrants.find(i => i.id === id)}
        } else {
          this.grantModel = {
            institution_receiving: '',
            job_title: '',
            participation_id: null,
            financing_source_id: null,
            start_date: null,
            end_date: null,
            description_result: ''
          }
        }

        this.displayGrantModal = true
      },
      async deleteGrant(id) {
        let res = await this.DELETE_RESEARCH_GRANT(id)
        if (res) {
          await this.getResearchGrants()
          this.$message({title: 'Успешно удалено'})
        }
      },
      async saveGrants() {
        this.loadingSave = true
        let res = false
        if (this.grantModel.id) {
          res = await this.PUT_RESEARCH_GRANT(this.grantModel)
        } else {
          res = await this.POST_RESEARCH_GRANT(this.grantModel)
        }
        if (res) {
          await this.getResearchGrants()
          this.closeGrantModal()
          this.$message({title: 'Успешно сохранено'})
        } else {
          this.$error({title: 'Произошла ошибка'})
        }
        this.loadingSave = false
      },
      closeGrantModal() {
        this.displayGrantModal = false
      },
      async getResearchGrants() {
        this.researchGrants = await this.GET_RESEARCH_GRANTS()
      }
    },
    async mounted() {
      await this.GET_FORMS_OF_PARTICIPATION()
      await this.GET_FINANCING_SOURCE()
      await this.getResearchGrants()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>