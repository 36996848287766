<template>
  <!-- Modal -->
  <div class="modal fade" id="addResearchWorkModal" tabindex="-1" aria-labelledby="addResearchWorkModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addResearchWorkModalLabel">Добавление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">

            <div class="form-row">

              <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Вид научно-исследовательской работы</label>
                <div class="col-md-3">
                  <select class="form-control form-select"
                          id="research_work_type"
                          @input="e=>{changeResearchWorksData(e, 'research_work_id')}">
                    <option v-for="(item, index) in [{id: 0, name: 'Выберите раздел'}, ...researchWorkType]" :value="item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
                <label for="sub_research_work_type" class="col-md-3 col-form-label">Подразделы
                  научно-исследовательской работы</label>
                <div class="col-md-3">
                  <select class="form-control form-select" id="sub_research_work_type"
                          @input="e=>{changeResearchWorksData(e, 'sub_research_work')}">
                    <option v-for="(item, index) in [{id: 0, name: 'Выберите раздел'}, ...getSubResearchWorkType]  " :value="item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>


              <div class="form-group row mt-4">
                <label for="research_work_name" class="col-md-3 col-form-label">Название работы</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" id="research_work_name"
                         placeholder="Название работы"
                         @change="e=>{changeResearchWorksData(e, 'book_name')}">
                </div>
              </div>

              <div v-if="postDbResearchWork?.sub_research_work==5" class="form-group row mt-4">
                <label for="journal_name" class="col-md-3 col-form-label">Название  журнала</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" id="journal_name"
                         @change="e=>{changeResearchWorksData(e, 'journal_name')}">
                </div>
              </div>

              <div v-if="postDbResearchWork?.sub_research_work==23" class="form-group row mt-4">
                <label for="journal_name" class="col-md-3 col-form-label">Название  журнала</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="language"
                          @change="e=>{changeResearchWorksData(e, 'journal_name')}">
                    <option v-for="(item, index) in [{name_publication: 'Выберите'},...journals]" :value="item.name_publication"
                            :key="index">{{ item.name_publication }}
                    </option>
                  </select>
                </div>
              </div>

              <div v-if="postDbResearchWork?.sub_research_work==5" class="form-group row mt-4">
                <label for="journal_quartile" class="col-md-3 col-form-label">Квартиль журнала</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="language"
                          @change="e=>{changeResearchWorksData(e, 'journal_quartile')}">
                    <option v-for="(item, index) in [{id:0,name: 'Выберите'},...quartile]" :value="item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div v-if="postDbResearchWork?.research_work_id==3" class="form-group row mt-4">
                <label for="conference_name" class="col-md-3 col-form-label">Название конференции</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" id="conference_name"
                         placeholder="Название конференции"
                         @change="e=>{changeResearchWorksData(e, 'conference_name')}">
                </div>
              </div>


              <div class="form-group row mt-4">
                <label for="language" class="col-md-3 col-form-label">Язык работы</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="language"
                          @change="e=>{changeResearchWorksData(e, 'language')}">
                    <option v-for="(item, index) in ALL_LANGUAGES" :value="item.id"
                            :key="index">{{ item.native_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="research_work_coauthors"
                       class="col-md-3 col-form-label">Соавторы</label>
                <div class="col-md-9">
                  <input id="research_work_coauthors" type="text" class="form-control"
                         placeholder="Соавторы" @change="e=>{changeResearchWorksData(e, 'co_autors')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="country" class="col-md-3 col-form-label">Страна</label>
                <div class="col-md-9">
                  <input id="country" type="text" class="form-control" placeholder="Страна"
                         @change="e=>{changeResearchWorksData(e, 'country')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="publishing_house" class="col-md-3 col-form-label">Издательство</label>
                <div class="col-md-9">
                  <input id="publishing_house" type="text" class="form-control"
                         placeholder="Издательство"
                         @change="e=>{changeResearchWorksData(e, 'publishing_house')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="research_work_year" class="col-md-3 col-form-label">Год</label>
                <div class="col-md-9">
                  <input id="research_work_year" type="text" class="form-control"
                         placeholder="Год" @change="e=>{changeResearchWorksData(e, 'year')}">
                </div>
              </div>

              <div class="form-group row mt-4" v-if="postDbResearchWork?.research_work_id==2">
                <label for="article_number" class="col-md-3 col-form-label">Номер журнала</label>
                <div class="col-md-9">
                  <input id="article_number" type="text" class="form-control"
                         placeholder="Номер журнала"
                         @change="e=>{changeResearchWorksData(e, 'journal_number')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="page_number" class="col-md-3 col-form-label">Номер страницы</label>
                <div class="col-md-9">
                  <input id="page_number" type="text" class="form-control"
                         placeholder="Номер страницы"
                         @change="e=>{changeResearchWorksData(e, 'page_number')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="issn" class="col-md-3 col-form-label">ISSN</label>
                <div class="col-md-9">
                  <input id="issn" type="text" class="form-control" placeholder="ISSN"
                         @change="e=>{changeResearchWorksData(e, 'issn')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="isbn" class="col-md-3 col-form-label">ISBN</label>
                <div class="col-md-9">
                  <input id="isbn" type="text" class="form-control" placeholder="ISBN"
                         @change="e=>{changeResearchWorksData(e, 'isbn')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="doi" class="col-md-3 col-form-label">DOI</label>
                <div class="col-md-9">
                  <input id="doi" type="text" class="form-control" placeholder="DOI"
                         @change="e=>{changeResearchWorksData(e, 'doi')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="link" class="col-md-3 col-form-label">URL-адрес</label>
                <div class="col-md-9">
                  <input id="link" type="text" class="form-control" placeholder="URL-адрес"
                         @change="e=>{changeResearchWorksData(e, 'link')}">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="note" class="col-md-3 col-form-label">Примечания</label>
                <div class="col-md-9">
                  <input id="note" type="text" class="form-control" placeholder="Примечания"
                         @change="e=>{changeResearchWorksData(e, 'note')}">
                </div>
              </div>

              <div class="form-group row mt-4" v-if="postDbResearchWork?.research_work_id==1">
                <label for="monographs_type_id" class="col-md-3 col-form-label">Рекомендовано</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="monographs_type_id"
                          @change="e=>{changeResearchWorksData(e, 'monographs_type_id')}">
                    <option v-for="(item, index) in [{id:0,name: 'Выберите'},...monographsType]" :value="item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4" v-if="postDbResearchWork?.research_work_id==1">
                <label for="recommended_organization" class="col-md-3 col-form-label">Файл</label>
                <div class="col-md-9">
                  <input class="form-control" type="file" id="file" ref="file"
                         @change="handleFileUpload"/>
                </div>
              </div>


            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="submitResearchWork">
            Добавить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->

</template>

<script>

  import {mapGetters, mapActions, mapState, mapMutations} from "vuex";

  export default {
    name: 'AddResearchWorksModal',
    computed: {

      ...mapState('researchwork', ['scopusData', 'researchWorkType', 'subResearchWorkType', 'selectedResearchWorkType', 'researchWorkEditIndex', 'postDbResearchWork', 'quartile', 'journals', 'monographsType']),
      ...mapGetters('researchwork', ["getFirstName", "getLastName", "getFullName"]),
      ...mapGetters('languageskills', ["ALL_LANGUAGES"]),
      getSubResearchWorkType() {
        let subResearch = this.subResearchWorkType.filter(i => i.parent_id == this.postDbResearchWork['research_work_id'])
        //console.log(subResearch, 'getSubResearchWorkType')
        return subResearch
      }
    },
    methods: {

      ...mapActions('researchwork', ["POST_DB_AUTHOR_ARTICLES", "GET_DB_AUTHOR_ARTICLES", "GET_RESEARCH_WORK_TYPE", "GET_SUB_RESEARCH_WORK_TYPE", "GET_QUARTILE", "GET_JOURNALS",
        "GET_MONOGRAPHS_TYPE"]),
      ...mapMutations('researchwork', ["SET_RESEARCH_WORK_DB_DATA"]),
      submitResearchWork() {
        this.POST_DB_AUTHOR_ARTICLES().then(res => {
          if (res.success == true) {
            this.GET_DB_AUTHOR_ARTICLES()
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
          } else {
            let errorText = '';
            for (let err of res.errors[0]) {
              errorText += err.message + '\n'
            }
            this.$error({title: 'Ошибка при добавлении', text: errorText})
          }
        })
      },
      changeResearchWorksData(e, property) {
        this.SET_RESEARCH_WORK_DB_DATA({value: e.target.value, property})
      },
      handleFileUpload(e) {
        let file = e.target.files[0];


        console.log(file, 'asdafgag')
        this.postDbResearchWork['file'] = file;
        console.log(this.postDbResearchWork['file'], 'file ')
        console.log(this.postDbResearchWork)
      },
    },
    created() {
      this.GET_QUARTILE()
      this.GET_JOURNALS()
      this.GET_MONOGRAPHS_TYPE()
      this.GET_RESEARCH_WORK_TYPE()
      this.GET_SUB_RESEARCH_WORK_TYPE()
    },
    mounted() {
    }
  }

</script>

<style scoped>

</style>
