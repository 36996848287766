<template>
  <div class="search-research-works border">
    
    <nav class="mt-4">
        <div class="nav nav-pills nav-fill" id="nav-tab" role="tablist">
            <a v-for="(active_tab, index) in active_tabs" :key="index"
               class="nav-link" :class="{ active: active_tab.isActive }"
               :id="active_tab[index]" data-bs-toggle="tab"
               :href="'#step'+index" @click="()=>moveTab(index)">{{active_tab.tab}}
            </a>
        </div>
    </nav>
    <div class="tab-content py-4" id="nav-tabContent">
        <div class="tab-pane fade" :class="{ show: active_tabs[0].isActive, active: active_tabs[0].isActive }"
             id="step0">
            <AuthorChoose/>
        </div>
        <div class="tab-pane fade" id="step1"
             :class="{ show: active_tabs[1].isActive, active: active_tabs[1].isActive }">
            <ResearchWorksChoose/>
        </div>
        <div class="tab-pane fade" id="step2"
             :class="{ show: active_tabs[2].isActive, active: active_tabs[2].isActive }">
          <EditResearchWorks/>
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="col-auto">
            <button type="button" class="btn btn-secondary" @click="prevTab"><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Предыдущий шаг</button>
        </div>
        <div class="col-auto">
            <button type="button" class="btn btn-primary" @click="nextTab">Следующий шаг&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </div>
    </div>
  </div>
</template>

<script>

    import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
    import AuthorChoose from '@/components/user-personal/research-work/AuthorChoose.vue';
    import ResearchWorksChoose from '@/components/user-personal/research-work/ResearchWorksChoose.vue';
    import EditResearchWorks from '@/components/user-personal/research-work/EditResearchWorks.vue';

    export default {
        name: 'SearchResearchWorks',
        components: {
            AuthorChoose,
            ResearchWorksChoose,
            EditResearchWorks
        },
        data() {
            return {
                author_lastname: '',
                author_firstname: '',
                active_tabs: [
                    {
                        tab: '1 шаг. Выбор автора',
                        isActive: true,
                    },
                    {
                        tab: '2 шаг. Выбор публикации',
                        isActive: false,
                    },
                    {
                        tab: '3 шаг. Редактировать публикации',
                        isActive: false,
                    }
                ],
            }
        },
        computed: {
            ...mapState('researchwork', ['scopusData']),
            ...mapGetters('researchwork', ["getFirstName", "getLastName"]),
            author_id: {
                set(value) {

                    console.log(value, 'check author id')
                }
            },
            checkedAll: {
                get() {
                    return this.scopusData.isCheckedAll
                },
                set(value) {
                    this.CHECKED_ALL(value)
                }
            },


        },
        methods: {
            ...mapActions('researchwork', ["GET_SCOPUS", "GET_SCOPUS_AUTHOR", "POST_AUTHOR_ARTICLES"]),
            ...mapMutations('researchwork', ["SET_AUTHOR_ID", "CHECKED_ALL", "UPDATE_CHECK_ARTICLE"]),

            moveTab(id) {
                let active_tabs = this.active_tabs;
                active_tabs.filter(item => item.isActive).forEach(item => {
                    item.isActive = false
                })
                active_tabs[id].isActive = true
            },
            nextTab() {
                let active_tabs = this.active_tabs;
                for (let i = 0; i < active_tabs.length; i++) {
                    if (active_tabs[i].isActive === true) {
                        active_tabs[i].isActive = false
                        if (i + 1 < active_tabs.length) {
                            active_tabs[i + 1].isActive = true
                        } else {
                            active_tabs[i].isActive = true
                        }
                        break;
                    }
                }
            },

            prevTab() {
                let active_tabs = this.active_tabs;
                for (let i = 0; i < active_tabs.length; i++) {
                    if (active_tabs[i].isActive === true) {
                        active_tabs[i].isActive = false
                        if (i - 1 >= 0) {
                            active_tabs[i - 1].isActive = true
                        } else {
                            active_tabs[i].isActive = true
                        }
                        break;
                    }
                }
            },


            submitArticles() {
                this.POST_AUTHOR_ARTICLES()
            },
            changeCheckArticle(index, e) {
                this.UPDATE_CHECK_ARTICLE({index, value: e.target.value})
            },

        },
        created() {

            //this.GET_SCOPUS();
            // this.GET_SCOPUS_AUTHOR().then(() => {
            //     if (this.scopusData.idOneAuthor) {
            //         this.GET_SCOPUS()
            //     }
            // });


            /*
            this.SET_AUTHOR_ID(55339235400)
            this.GET_SCOPUS().then(() => {
                this.POST_AUTHOR_ARTICLES()

            })
             */


        },
        mounted() {
        }
    }

</script>

<style scoped>

</style>
