<template>

    <div class="mt-4">
        <EditResearchWorksModal :pageId="'EditResearchWorks'"/>

        <div class="container text-center" v-if="loading">
            <p>Загрузка статей</p>
            <div class="loader "></div>
        </div>




        <div>
            <nav class="d-flex justify-content-center" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left"
                                                                                         aria-hidden="true"></i></a>
                    </li>
                    <li class="page-item" v-for="item in pageCount" :key="item">
                        <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right"
                                                                                           aria-hidden="true"></i></a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="row" v-for="(item, index) in paginated" :key="index">

            <div class="card">
                <div class="card-body row">
                    <div class="col-11">
                        <div class="card-title mb-2">
                            <strong>
                                {{ item.book_name }}
                            </strong>
                        </div>
                        <div class="mb-1">Publisher: {{ item.publishing_house }}</div>
                        <p class="card-text">
                            <span class="bg-success text-light me-2 border-2 p-1"> {{ getSubResearchWork(item.sub_research_work) }} </span>
                            <span class="me-2">DOI: {{ item.doi }}</span>
                            <span class="me-2">ISSN: {{ item.issn }}</span>
                        </p>
                        <p class="card-text">{{ item.co_autors }}</p>
                    </div>
                    <div class="col-1">

                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                                data-bs-target="#EditResearchWorks" @click="setResearchWorkEditIndex(index)"
                                v-if="current == 1">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                                data-bs-target="#EditResearchWorks"
                                @click="setResearchWorkEditIndex(indexStart + index)" v-if="current > 1">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>

        </div>

        <div>
            <nav class="d-flex justify-content-center" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left"
                                                                                         aria-hidden="true"></i></a>
                    </li>
                    <li class="page-item" v-for="item in pageCount" :key="item">
                        <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right"
                                                                                           aria-hidden="true"></i></a>
                    </li>
                </ul>
            </nav>
        </div>


    </div>


</template>
<script>

    import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
    import EditResearchWorksModal from '@/components/user-personal/research-work/EditResearchWorksModal.vue'

    export default {
        name: 'EditResearchWorks',
        components: {
            EditResearchWorksModal,
        },
        data() {
            return {
                current: 1,
                pageSize: 5
            }
        },
        computed: {
            ...mapState('researchwork', ['scopusData', 'loading', 'researchWorkType', 'subResearchWorkType', 'selectedResearchWorkType']),
            ...mapGetters('researchwork', ["getFirstName", "getLastName"]),
            ...mapGetters('languageskills', ["ALL_LANGUAGES"]),
            getSubResearchWorkType() {
                let subResearch = this.subResearchWorkType.filter(i => i.parent_id == this.scopusData.dbAuthorArticles[this.researchWorkEditIndex].research_work_id)
                console.log(subResearch, 'getSubResearchWorkType')
                return subResearch
            },
            indexStart() {
                return (this.current - 1) * this.pageSize;
            },
            indexEnd() {
                return this.indexStart + this.pageSize;
            },
            paginated() {
                return this.scopusData.dbAuthorArticles.slice(this.indexStart, this.indexEnd);
            },
            pageCount() {
                let pages = []
                for (let i = 1; i <= Math.round(this.scopusData.dbAuthorArticles.length / this.pageSize); i++) {
                    pages.push(i)
                }
                return pages
            },

        },
        methods: {
            ...mapActions('researchwork', ["POST_AUTHOR_ARTICLES", "PUT_AUTHOR_ARTICLES", "GET_DB_AUTHOR_ARTICLES", "GET_RESEARCH_WORK_TYPE", "GET_SUB_RESEARCH_WORK_TYPE"]),
            ...mapMutations('researchwork', ["UPDATE_RESEARCH_WORK_TYPE", "UPDATE_SUB_RESEARCH_WORK_TYPE", "UPDATE_RESEARCH_WORK_DATA", "SET_RESEARCH_WORK_EDIT_INDEX"]),
            setResearchWorkEditIndex(idx) {
                this.SET_RESEARCH_WORK_EDIT_INDEX(idx)
            },
            prev() {
                if (this.current > 1) {
                    this.current--;
                }
            },
            next() {
                if (this.current < this.scopusData.dbAuthorArticles.length / this.pageSize) {
                    this.current++;
                }
            },
            movePageNumber(page) {
                this.current = page
            },
            getSubResearchWork(id) {
                let subResearchWorkTypeId = this.subResearchWorkType.find(i => i.id == id)
                return subResearchWorkTypeId ? subResearchWorkTypeId.name : ''
            }

            // changeResearchWorkType(e) {
            //   this.UPDATE_RESEARCH_WORK_TYPE({index: this.researchWorkEditIndex, value: e.target.value})
            // },
            // changeSubResearchWorkType(e) {
            //   this.UPDATE_SUB_RESEARCH_WORK_TYPE({index: this.researchWorkEditIndex, value: e.target.value})
            // },
            // updateResearchWork(id){
            //   this.PUT_AUTHOR_ARTICLES(id)
            // },
            // changeResearchWorksData(e, property) {
            //   this.UPDATE_RESEARCH_WORK_DATA({index: this.researchWorkEditIndex, value: e.target.value, property})
            // }
        },
        created() {
            this.GET_RESEARCH_WORK_TYPE()
            this.GET_SUB_RESEARCH_WORK_TYPE()
            this.GET_DB_AUTHOR_ARTICLES()
        },
        mounted() {
        }
    }

</script>

<style scoped>

    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 90px;
        height: 90px;
        margin-left: auto;
        margin-right: auto;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>