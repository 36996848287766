<template>

      <div class="education-file-form mt-4 mb-4">
        <h4 class="text-center">Добавление документов об образовании</h4>

        <div class="form-row" v-for="(educationFileLink, index) in education_form.educationFileLinks"
             :key="index">
          <div class="form-group row mt-4">
            <div class="col-md-11">
              <a :href="'https://back.uib.kz/uploads/' + educationFileLink.file">{{ educationFileLink.name }}</a>
            </div>
            <div class="col-md-1">
              <i class="far fa-trash-alt me-auto"
                 @click="deleteFileLinkRow(index, educationFileLink)"></i>
            </div>
          </div>
        </div>

        <div class="form-row mb-2" v-for="(educationFile, index) in educationFiles" :key="index">

          <div class="form-group row mt-4">


            <div class="col-md-11">
              <input class="form-control" type="file" id="file" ref="file" title=" " :data-index="index"
                     v-on:change="handleFileUpload"/>
            </div>

            <div class="col-md-1">
              <i class="far fa-trash-alt me-auto" @click="deleteRow(index, educationFile)"></i>
            </div>
          </div>

        </div>
        <div class="form-group">
          <button type='button' class="btn btn-secondary" @click="addNewRow">
            <i class="fas fa-plus-circle"></i>Добавить
          </button>
        </div>
        
        <div class="row mt-4">
          <div class="col-md-12 text-center">
            <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitEducationFile()">Сохранить
            </button>
          </div>
        </div>
      </div>




</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import axios from 'axios'
import httpClient from "../../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "EducationFileForm",
  data() {
    return {
      educationFiles: [{file: ''}],
    }
  },
  computed: {
    ...mapState('education', ['education_form']),
  },
  methods: {
    ...mapActions('education', ['GET_EDUCATION_FILES', 'DELETE_EDUCATION_FILES']),
    deleteRow(index, educationFile) {
      var idx = this.educationFiles.indexOf(educationFile);
      if (idx > -1) {
        this.educationFiles.splice(idx, 1);
      }
    },
    deleteFileLinkRow(index, educationFileLink) {
      if (this.education_form.educationFileLinks[index].id) {
        this.DELETE_EDUCATION_FILES(index);
        console.log('delete ' + index)
        this.$message({title: 'Удаление', text: 'Файл успешно удален'})
      }
      var idx = this.education_form.educationFileLinks.indexOf(educationFileLink);
      if (idx > -1) {
        this.education_form.educationFileLinks.splice(idx, 1);
      }
    },
    addNewRow() {
      this.educationFiles.push({});
    },

    submitEducationFile() {

      let formData = new FormData();


      for (let i = 0; i < this.educationFiles.length; i++) {
        if (this.educationFiles[i].file != '') {
          let file = this.educationFiles[i].file;
          let name = 'file[' + i + ']';
          console.log(file + ' ' + name);

          formData.append(name, file);
        }
      }
      console.log(Object.fromEntries(formData), 'hello from formdata')


      httpClient.post('questionnaire/pps-data-files/create' + '?access-token=' + getCookie('ACCESS_TOKEN'),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(res => {
        this.GET_EDUCATION_FILES()
        console.log(res.data, 'file_data')
        this.educationFiles.splice(0);
        this.$message({title: 'Добавление', text: 'Файл успешно добавился'})
        console.log('SUCCESS!! file education')
      }).catch(err => {
        console.log(err);
        console.log('FAILURE!! file education')
      });

    },
    handleFileUpload(e) {
      let file = e.target.files[0];
      let index = e.target.dataset.index;
      console.log(e.target.dataset.index);
      console.log(file);
      this.educationFiles[index] = {file: file};
    }
  },
  created() {
    this.GET_EDUCATION_FILES();
  }
}

</script>
<style scoped>

</style>