<template>
  <div>
    <h4 class="text-center my-4">Опыт преподавания</h4>
    <div class="my-3">
      <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary m-1"
              @click="addTeacherCourses"/>
    </div>
    <div class="my-3">

      <div class="mb-4">
        <div v-for="(discipline, disciplineKey) in teacherCoursesFront" :key="disciplineKey"
             class="border rounded-3 p-2 my-3">
          <h5 class="mb-2">
            {{ discipline[0].education_discipline_name }}
          </h5>
          <div v-for="(item, index) in discipline" :key="index" class="mb-2">
            <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.academic_year_name }}</span>
            <span v-if="item.moodle_course" class="px-2 py-1 me-2">
              <a :href="item.moodle_course" class="text-decoration-none" target="_blank">
                {{item.course_name}}
              </a>
            </span>
            <span v-if="item.file_url">
                Силлабус:
                <a :href="item.file_url.startsWith('https://back.uib.kz')?item.file_url:'https://back.uib.kz/uploads/'+item.file_url" target="_blank">
                  <i class="pi pi-file-pdf text-danger"></i>
                </a>
            </span>
          </div>
          <div class="mt-3">
            <span class="bg-light rounded-3 px-2 py-1 me-2">{{ discipline[0].study_level_name }}</span>
            <span class="bg-light rounded-3 px-2 py-1 me-2">{{ discipline[0].language_name }}</span>
          </div>
        </div>
      </div>


      <div>
        <div v-for="(item, index) in teacherCourses" :key="index" class="border rounded-3 p-2 my-3">
          <div class="row">
            <div class="col-md-8">
              <h5>{{ item.discipline_name }}</h5>
            </div>
            <div class="col-md-4 text-end">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-1"
                      @click="editTeacherCourse(index)" :disabled="item.can_change === 0"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                      @click="deleteTeacherCourse(item.id)" :disabled="item.can_change === 0"/>
            </div>
          </div>
          <div class="mb-2">
            <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.academic_year_name }}</span>
            Силлабус:
            <a :href="item.syllabus_url" target="_blank">
              <i class="pi pi-file-pdf text-danger"></i>
            </a>
          </div>
          <div>
            <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.study_level_name }}</span>
            <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.language_name }}</span>
            <span>Количество лет преподавания: {{ item.teaching_years }}</span>
          </div>
        </div>

      </div>


    </div>

    <!-- add or update teachingExperiences modal -->
    <Dialog :header="teacherCourse?.id?'Обновление дисциплины':'Добавление дисциплины'"
            v-model:visible="displayTeacherCourse"
            :style="{width: '98%', maxWidth: '1000px'}" :modal="true" :closable="false">
      <div class="form-row my-3">
        <div class="form-group row mt-3">
          <label class="col-md-3">Дисциплина</label>
          <div class="col-md-9">
            <Dropdown
              style="width: 100%"
              v-model="teacherCourse.discipline_id"
              :options="disciplines"
              optionLabel="name"
              optionValue="id"
              placeholder="Выберите дисциплину"
              :filter="true"
              @filter="searchDisciplines($event.value)"
            />
          </div>
        </div>

        <div class="form-group row mt-3">
          <label class="col-md-3">Силлабус</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="syllabus_id"
                    v-model="teacherCourse.syllabus_id">
              <option v-for="(item, index) in syllabus_form.syllabusInfos" :value="item.id" :key="index">
                {{ item?.educationDiscipline?.name }} - {{ item?.evaluationOption?.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label class="col-md-3">Последний год преподавания</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="academic_year_id"
                    v-model="teacherCourse.academic_year_id">
              <option v-for="(item, index) in educationCurriculum_form.academicYear" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label class="col-md-3">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="study_level_id"
                    v-model="teacherCourse.study_level_id">
              <option v-for="(item, index) in studyLevels" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label class="col-md-3">Язык</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="language_id"
                    v-model="teacherCourse.language_id">
              <option v-for="(item, index) in educationdiscipline_form.languages" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="teaching_years" class="col-md-3 col-form-label">Количество лет преподавания</label>
          <div class="col-md-9">
            <input id="teaching_years" type="text" class="form-control" placeholder="Количество лет преподавания"
                   v-model="teacherCourse.teaching_years">
          </div>
        </div>

      </div>
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeTeacherCourseModal" class="p-button-text"/>
        <Button :label="teacherCourse?.id?'Обновить':'Создать'" icon="pi pi-check" autofocus
                @click="saveTeacherCourse"/>
      </template>
    </Dialog>
    <!-- end add or update teachingExperiences modal -->

  </div>
</template>


<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: "TeachingForm",
    data() {
      return {
        teacherCoursesFront: [],
        teacherCourses: [],
        teacherCourse: {},
        displayTeacherCourse: false,
        academic_year: [],
        disciplines: []
      }
    },
    computed: {
      ...mapState('educationdiscipline', ['educationdiscipline_form']),
      ...mapState('educationCurriculum', ['educationCurriculum_form']),
      ...mapState('syllabus', ['syllabus_form']),
      studyLevels() {
        return this.educationdiscipline_form.studyLevel
      }
    },
    methods: {
      ...mapActions('syllabus', ['SEARCH_DISCIPLINE']),
      ...mapActions('educationCurriculum', ['GET_SP_ACADEMIC_YEAR']),
      ...mapActions('syllabus', ['GET_SYLLABUS_DATA']),
      ...mapActions('teachingExperiences', ['GET_TEACHING_EXPERIENCES', 'POST_TEACHING_EXPERIENCES', 'PUT_TEACHING_EXPERIENCES', 'DELETE_TEACHING_EXPERIENCES',
        'GET_TEACHING_EXPERIENCES_FRONT']),
      addTeacherCourses() {
        this.teacherCourse = {
          discipline_name: '',
          discipline_id: null,
          academic_year_name: '',
          academic_year_id: null,
          language_name: '',
          language_id: null,
          teaching_years: null,
          syllabus_url: '',
          syllabus_id: null,
          can_change: 1,
          study_level_name: '',
          study_level_id: null
        }
        this.displayTeacherCourse = true
        console.log(this.teacherCourse, 'addTeacherCourses')
      },
      editTeacherCourse(index) {
        this.teacherCourse = this.teacherCourses[index]
        this.disciplines = [
          {
            id: this.teacherCourse.discipline_id,
            name: this.teacherCourse.discipline_name
          },
        ]
        this.displayTeacherCourse = true
        console.log(this.teacherCourse, 'editTeacherCourse')
      },

      async saveTeacherCourse() {
        let res = false
        if (this.teacherCourse.id) {
          res = await this.PUT_TEACHING_EXPERIENCES(this.teacherCourse)
        } else {
          res = await this.POST_TEACHING_EXPERIENCES(this.teacherCourse)
        }
        if (res) {
          this.teacherCourses = await this.GET_TEACHING_EXPERIENCES()
          this.closeTeacherCourseModal()
          this.$message({title: 'Успешно сохранено'})
        }
      },

      async deleteTeacherCourse(id) {
        let res = await this.DELETE_TEACHING_EXPERIENCES(id)
        if (res) {
          this.teacherCourses = await this.GET_TEACHING_EXPERIENCES()
          this.$message({title: 'Успешно удалено'})
        }
      },
      async searchDisciplines(name) {
        if (name.length > 3) {
          this.disciplines = await this.SEARCH_DISCIPLINE(name)
        }
      },
      closeTeacherCourseModal() {
        this.displayTeacherCourse = false
      }
    },
    async mounted() {
      await this.GET_SP_ACADEMIC_YEAR()
      await this.GET_SYLLABUS_DATA()
      this.teacherCourses = await this.GET_TEACHING_EXPERIENCES()
      this.teacherCoursesFront = await this.GET_TEACHING_EXPERIENCES_FRONT()

    }
  }

</script>

<style lang="scss" scoped>
  p {
    line-height: 1.5;
    margin: 0;
  }
</style>

