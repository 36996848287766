<template>
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-md-3">
        <div class="nav flex-column nav-pills mt-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">

          <a class="nav-link active " id="v-pills-personal-tab" data-bs-toggle="pill" href="#v-pills-personal"
             role="tab" aria-controls="v-pills-personal" aria-selected="true"><i class="fa fa-user"
                                                                                 aria-hidden="true"></i>&nbsp;Личная
            информация</a>

          <a class="nav-link" id="v-pills-education-tab" data-bs-toggle="pill" href="#v-pills-education" role="tab"
             aria-controls="v-pills-education" aria-selected="false"><i class="fa fa-graduation-cap"
                                                                        aria-hidden="true"></i>&nbsp;Образование</a>

          <!--          <a class="nav-link" id="v-pills-science-degree-tab" data-bs-toggle="pill" href="#v-pills-science-degree"-->
          <!--             role="tab"-->
          <!--             aria-controls="v-pills-science-degree" aria-selected="false"><i class="fa fa-graduation-cap"-->
          <!--                                                                             aria-hidden="true"></i>&nbsp;Научная-->
          <!--            степень</a>-->

          <a class="nav-link" id="v-pills-job-tab" data-bs-toggle="pill" href="#v-pills-job" role="tab"
             aria-controls="v-pills-job" aria-selected="false"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;
            Опыт работы</a>

          <a class="nav-link" id="v-pills-teaching-tab" data-bs-toggle="pill"
             href="#v-pills-teaching" role="tab"
             aria-controls="v-pills-teaching" aria-selected="false"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;
            Опыт преподавания</a>

          <a class="nav-link" id="v-pills-accreditation-tab" data-bs-toggle="pill"
             href="#v-pills-accreditation" role="tab"
             aria-controls="v-pills-accreditation" aria-selected="false"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;
            Опыт прохождения аккредитации</a>

          <a class="nav-link" id="v-pills-grantswon-tab" data-bs-toggle="pill"
             href="#v-pills-grantswon" role="tab"
             aria-controls="v-pills-grantswon" aria-selected="false"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;
            Гранты, проекты</a>

          <a class="nav-link" id="v-pills-languageskills-tab" data-bs-toggle="pill" href="#v-pills-languageskills"
             role="tab" aria-controls="v-pills-languageskills" aria-selected="false"><i class="fa fa-globe"
                                                                                        aria-hidden="true"></i>&nbsp;
            Языковые навыки</a>

          <a class="nav-link" id="v-pills-researchwork-tab" data-bs-toggle="pill" href="#v-pills-researchwork"
             role="tab" aria-controls="v-pills-researchwork" aria-selected="false"><i class="fa fa-book"
                                                                                      aria-hidden="true"></i>&nbsp; НИР</a>

        </div>
      </div>
      <div class="col-md-9">
        <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-personal" role="tabpanel"
               aria-labelledby="v-pills-personal-tab">
            <Questionnaire/>
          </div>
          <div class="tab-pane fade" id="v-pills-education" role="tabpanel"
               aria-labelledby="v-pills-education-tab">
            <Education/>
          </div>
          <!--          <div class="tab-pane fade" id="v-pills-science-degree" role="tabpanel"-->
          <!--               aria-labelledby="v-pills-science-degree-tab">-->
          <!--            <ScienceDegreeForm/>-->
          <!--          </div>-->
          <div class="tab-pane fade" id="v-pills-job" role="tabpanel"
               aria-labelledby="v-pills-job-tab">
            <JobForm/>
          </div>
          <div class="tab-pane fade" id="v-pills-teaching" role="tabpanel"
               aria-labelledby="v-pills-teaching-tab">
            <TeachingForm/>
          </div>
          <div class="tab-pane fade" id="v-pills-grantswon" role="tabpanel"
               aria-labelledby="v-pills-grantswon-tab">
            <GrantsWon/>
          </div>
          <div class="tab-pane fade" id="v-pills-languageskills" role="tabpanel"
               aria-labelledby="v-pills-languageskills-tab">
            <LanguageSkillsForm/>
          </div>
          <div class="tab-pane fade" id="v-pills-researchwork" role="tabpanel"
               aria-labelledby="v-pills-researchwork-tab">
            <ResearchWork/>
          </div>
          <div class="tab-pane fade" id="v-pills-accreditation" role="tabpanel"
               aria-labelledby="v-pills-accreditation-tab">
            <Accreditation/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Questionnaire from '@/views/user-personal/Questionnaire.vue'
import Education from '@/views/user-personal/Education.vue'
//import ScienceDegreeForm from '@/components/user-personal/ScienceDegreeForm.vue'
import JobForm from '@/components/user-personal/JobForm.vue'
import TeachingForm from '@/components/user-personal/TeachingForm.vue'
import LanguageSkillsForm from '@/components/user-personal/LanguageSkillsForm.vue'
import ResearchWork from '@/views/user-personal/ResearchWork.vue'
import GrantsWon from '@/components/user-personal/GrantsWon.vue'
import Accreditation from '@/components/user-personal/Accreditation.vue'
import {getCookie} from "@/utils/helpers/cookies.helpers";


export default {
  name: 'Main',
  components: {
    Questionnaire,
    Education,
    //ScienceDegreeForm,
    JobForm,
    TeachingForm,
    LanguageSkillsForm,
    ResearchWork,
    GrantsWon,
    Accreditation
  },
  methods: {
    checkRole(i) {
      const roles = JSON.parse(getCookie('ROLES')) || []
      return roles.includes(i)
    }
  },
}

</script>

<style scoped>

</style>