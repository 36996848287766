<template>

    <div class="language-skills-form mt-4">
        <h4 style="text-align: center">Знание языков</h4>

        <div class="row mt-4">
            <div class="col-md-12 mt-4">
                <label for="language" class="col-form-label">Языки</label>
            </div>

            <div class="col-md-12">
                <div class="form-row" v-for="(languageInfo, index) in languageskills_form.languageInfos"
                     :key="index">
                    <div class="language-remove-item__button d-flex flex-row-reverse">
                        <i class="fa fa-times" @click="deleteRowLanguageInfo(index)"></i>
                    </div>
                    <div class="form-group row">

                        <div class="col-md-4">
                            <select class="form-control form-select" id="language"
                                    @input="changeLanguageSkillsData(languageInfo, 'lang_id', $event)">
                                <option v-for="(item, index) in ALL_LANGUAGES" :value="item.id" :key="index"
                                        :selected="item.id == languageInfo.lang_id">{{ item.native_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <select class="form-control form-select"
                                    @input="changeLanguageSkillsData(languageInfo, 'lang_level_id', $event)">
                                <option v-for="(item, index) in ALL_LANGUAGES_LEVEL" :value="item.id"
                                        :key="index"
                                        :selected="item.id == languageInfo.lang_level_id">{{ item.full_name }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <input type="checkbox" id="certificate_status"
                                   :checked="1 == languageInfo.certificate"
                                   @change="changeLanguageSkillsData(languageInfo, 'certificate', $event, 'checked')"
                            />
                            <br>
                            <label class="form-check-label" for="certificate_status">Есть сертификат?</label>
                        </div>

                        <div class="col-md-8" v-if="languageInfo.certificate == 1">

                          <div class="row mt-3">

                            <div class="col-md-4">
                              <div>Тип</div>
                              <div>
                                <select class="form-control form-select" id="language"
                                        @input="changeLanguageSkillsData(languageInfo, 'language_certification_type_id', $event)">
                                  <option v-for="(item, index) in [{id:0, name:'Выбрать'},...languageCertificationTypes]"
                                          :value="item.id" :key="index"
                                          :selected="item.id == languageInfo.language_certification_type_id">
                                    {{ item.name }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div>Количество баллов</div>
                              <div>
                                <input type="number" class="form-control" :value="languageInfo.score"
                                       @change="changeLanguageSkillsData(languageInfo, 'score', $event)">
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div>Дата получения</div>
                              <div>
                                <input type="date" class="form-control" :value="languageInfo.date_receive"
                                       @change="changeLanguageSkillsData(languageInfo, 'date_receive', $event)">
                              </div>
                            </div>


                          </div>


                          <div v-if="languageInfo.file==='' ||  typeof languageInfo.file == 'object'">

                              <div class="my-3">
                                <input class="form-control" type="file" id="file" ref="file" title=" "
                                       :data-index="index"
                                       :name="'file[' + index + ']'"
                                       @change="handleFileUpload"/>
                              </div>
                            </div>
                            <div class="my-3" v-else>
                                <a :href="'https://back.uib.kz/uploads/' + languageInfo.file"> {{ languageInfo.file
                                    }} </a>
                            </div>
                        </div>

                        <hr/>
                    </div>

                </div>
            </div>
        </div>


        <div class="form-group">
            <button @click="addRowLanguageInfo" type="button" class="btn btn-secondary"><i
                    class="fas fa-plus-circle"></i>
                Добавить
            </button>
        </div>

        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button class="btn btn-pill text-white btn-block btn-primary"
                        v-on:click="submitLanguageSkills()">Сохранить
                </button>
            </div>
        </div>
    </div>

    <FlashMessage group="languageskills"></FlashMessage>

</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "LanguageSkillsForm",
        computed: {
            ...mapState('languageskills', ['languageskills_form', 'languages', 'languageCertificationTypes']),
            ...mapGetters('languageskills', ['ALL_LANGUAGES', 'ALL_LANGUAGES_LEVEL']),
        },
        methods: {
            ...mapActions('languageskills', ['GET_LANGUAGES', 'GET_LANGUAGES_LEVEL', 'POST_LANGUAGE_SKILLS_DATA', 'PUT_LANGUAGE_SKILLS_DATA', 'GET_LANGUAGE_SKILLS_DATA', 'DELETE_LANGUAGE_SKILLS_DATA',
            'GET_LANGUAGE_CERTIFICATION_TYPES']),
            ...mapMutations('languageskills', ['ADD_ROW_LANGUAGE_INFO', 'DELETE_ROW_LANGUAGE_INFO', 'UPDATE_LANGUAGE_SKILLS_DATA']),

            addRowLanguageInfo() {
                this.ADD_ROW_LANGUAGE_INFO();
            },
            deleteRowLanguageInfo(index) {
                if (this.languageskills_form.languageInfos[index].id) {
                    this.DELETE_LANGUAGE_SKILLS_DATA(index);
                }
                this.DELETE_ROW_LANGUAGE_INFO(index);
            },
            handleFileUpload(e) {
                let file = e.target.files[0];
                let index = e.target.dataset.index;
                console.log(e.target.dataset.index);
                console.log(e.target.name, 'name');
                this.languageskills_form.languageInfos[index].file = file;
                console.log(this.languageskills_form.languageInfos[index].file, 'file ' + index)
            },
            submitLanguageSkills() {

                this.PUT_LANGUAGE_SKILLS_DATA().then(res => {
                    this.GET_LANGUAGE_SKILLS_DATA();
                    if (res.success == true) {
                        this.$flashMessage.show({
                            group: 'languageskills',
                            type: 'success',
                            title: 'Обновление',
                            text: 'Данные успешно обновились',
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    } else if (res.success == false) {
                        this.$flashMessage.show({
                            group: 'languageskills',
                            type: 'error',
                            title: 'Ошибка при обновлении',
                            text: res.errors,
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    }
                })


                this.POST_LANGUAGE_SKILLS_DATA()
                    .then(res => {
                        this.GET_LANGUAGE_SKILLS_DATA();
                        if (res.success == true) {
                            this.$flashMessage.show({
                                group: 'languageskills',
                                type: 'success',
                                title: 'Добавление',
                                text: 'Данные успешно добавились',
                                time: 5000,
                                blockClass: 'custom-block-class'
                            })
                        } else if (res.success == false) {
                            let errorText = '';
                            console.log(res)
                            console.log('dfdf')
                            for (let err of res.errors[0]) {
                                errorText += err.message + '\n'
                            }
                            this.$flashMessage.show({
                                group: 'languageskills',
                                type: 'error',
                                title: 'Ошибка при добавлении',
                                text: errorText,
                                time: 10000
                            })
                        }
                    })

            },
            changeLanguageSkillsData(item, property, e, val = 'value') {
                const value = val != 'checked' ? e.target[val] : e.target[val] ? 1 : 0
                this.UPDATE_LANGUAGE_SKILLS_DATA({item, property, value})
            },
        },
        created() {
            this.GET_LANGUAGES();
            this.GET_LANGUAGE_CERTIFICATION_TYPES();
            this.GET_LANGUAGES_LEVEL();
            this.GET_LANGUAGE_SKILLS_DATA();

        }
    }

</script>
<style scoped>
    .language-remove-item__button {
        color: red;
    }
</style>
