<template>

    <div class="children-form mt-4">
        <h4 style="text-align: center">Дети</h4>

        <div class="form-row" v-for="(childrenInfo, index) in children_form.childrenInfos" :key="index">
            <div class="children-remove-item__button d-flex flex-row-reverse">
                <i class="fa fa-times" @click="deleteRowChildrenInfo(index)"></i>
            </div>


            <div class="form-group row mt-4">
                <label for="children_birth_date" class="col-md-3 col-form-label">Дата рождения</label>
                <div class="col-md-9">
                    <input id="children_birth_date" type="date" class="form-control"
                           :value="convertUnixtimeToDate(childrenInfo.birth_date)"
                           @input="changeChildrenBirthDate(index, $event)">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Пол</label>
                <div class="col-md-9">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="female" value="2"
                               @change="e=>{changeChildrenGender(index, e)}" :checked="2 == childrenInfo.gender">
                        <label class="form-check-label" for="female">Женский</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="male" value="1"
                               @change="e=>{changeChildrenGender(index, e)}" :checked="1 == childrenInfo.gender">
                        <label class="form-check-label" for="male">Мужской</label>
                    </div>
                </div>
            </div>

            <hr>

        </div>
        <div class="form-group">
            <button @click="addRowChildrenInfo" type="button" class="btn btn-secondary"><i
                    class="fas fa-plus-circle"></i>
                Добавить
            </button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12 text-center">
            <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitChildren()">Сохранить
            </button>
        </div>
    </div>

    <FlashMessage group="children"></FlashMessage>

</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "ChildrenForm",
        computed: {
            ...mapState('children', ['children_form']),
        },
        data: () => ({
            startDate: '1920-01-01',
        }),

        methods: {
            ...mapActions('children', ['POST_CHILDREN_DATA', 'PUT_CHILDREN_DATA', 'GET_CHILDREN_DATA', 'DELETE_CHILDREN_DATA']),
            ...mapMutations('children', ['UPDATE_CHILDREN_GENDER', 'UPDATE_CHILDREN_BIRTH_DATE',
                'DELETE_ROW_CHILDREN_INFO', 'ADD_ROW_CHILDREN_INFO']),

            deleteRowChildrenInfo(index) {
                if (this.children_form.childrenInfos[index].id) {
                    this.DELETE_CHILDREN_DATA(index);
                }
                this.DELETE_ROW_CHILDREN_INFO(index);
            },
            addRowChildrenInfo() {
                this.ADD_ROW_CHILDREN_INFO();
            },
            submitChildren() {


                this.PUT_CHILDREN_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_CHILDREN_DATA();
                        this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
                    } else if (res.success == false) {
                        this.$error({title: 'Ошибка при обновлении', text: res.errors})
                    }
                })


                this.POST_CHILDREN_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_CHILDREN_DATA();
                        this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
                    } else {
                        let errorText = '';
                        for (let err of res.errors[0]) {
                            errorText += err.message + '\n'
                        }
                        this.$error({title: 'Ошибка при добавлении', text: errorText})
                    }
                })
            },
            convertUnixtimeToDate(timestamp) {
                let date = new Date(timestamp * 1000)
                let month = date.getMonth() + 1
                let day = date.getDate()
                let monthZero = month < 10 ? '0' : ''
                let dayZero = day < 10 ? '0' : ''
                return date.getFullYear() + '-' + monthZero + month + '-' + dayZero + day
            },
            changeChildrenGender(index, e) {
                this.UPDATE_CHILDREN_GENDER({index, value: e.target.value})
            },
            changeChildrenBirthDate(index, e) {
                if (new Date(e.target.value) > new Date(this.startDate)) {
                    this.UPDATE_CHILDREN_BIRTH_DATE({index, value: e.target.value})
                }
            }
        },
        created() {
            this.GET_CHILDREN_DATA();
        }
    }
</script>

<style scoped>

</style>