<template>

    <div class="job-form mt-4">
        <h4 style="text-align: center">Опыт работы</h4>
        <p class="under_job_text text-center">перечислить с последнего места работы и все предыдущие</p>

        <div class="form-row" v-for="(jobInfo, index) in job_form.jobInfos" :key="index">
            <div class="job-remove-item__button d-flex flex-row-reverse">
                <i class="fa fa-times" @click="deleteRowJobInfo(index)"></i>
            </div>


            <div class="form-group row mt-4">
                <label for="company" class="col-md-3 col-form-label">Организация</label>
                <div class="col-md-9">
                    <input id="company" type="text" class="form-control" placeholder="Название компании"
                           :value="jobInfo.name" @change="changeJobName(index, $event)">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="address" class="col-md-3 col-form-label">Адрес</label>
                <div class="col-md-9">
                    <input id="address" type="text" class="form-control" placeholder="Адрес компании"
                           :value="jobInfo.address" @change="changeJobAddress(index, $event)">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="field_of_activity" class="col-md-3 col-form-label">Сфера деятельности</label>
                <div class="col-md-9">
                    <input id="field_of_activity" type="text" class="form-control"
                           placeholder="Сфера деятельности компании"
                           :value="jobInfo.field_of_activity" @change="changeFieldOfActivity(index, $event)">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="job_start" class="col-md-3 col-form-label">Начало работы</label>
                <div class="col-md-9">
                    <input id="job_start" type="date" class="form-control"
                           :value="convertTimestampToDate(jobInfo.start_year)"
                           @change="changeJobStartYear(index, $event)">
                </div>
            </div>
            <div class="form-group row mt-4">
                <label for="checkbox" class="col-md-3 col-form-label">По настоящее время</label>
                <div class="col-md-9">
                    <input type="checkbox" id="checkbox"
                           :checked="1 == jobInfo.working_now_status"
                           @change="changeJobEndYearCheck(index, $event)"/>
                </div>
            </div>
            <div class="form-group row mt-4" v-if="jobInfo.working_now_status === 0">
                <label for="job_end" class="col-md-3 col-form-label">Окончание работы</label>
                <div class="col-md-9">
                    <input id="job_end" type="date" class="form-control"
                           :value="convertTimestampToDate(jobInfo.end_year)"
                           @change="changeJobEndYear(index, $event)">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="job_title" class="col-md-3 col-form-label">Должность</label>
                <div class="col-md-9">
                    <input id="job_title" type="text" class="form-control" placeholder="Должность"
                           :value="jobInfo.title"
                           @change="changeJobTitle(index, $event)">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="job_description" class="col-md-3 col-form-label">Функциональные обязанности</label>
                <div class="col-md-9">
              <textarea id="job_description" @change="changeJobDescription(index, $event)" :value="jobInfo.description"
                        class="form-control" rows="5"
                        placeholder="Расскажите о своих качествах, знаниях, увлечениях, которые, как вам кажется, будут полезны работодателю"></textarea>
                </div>
            </div>
            <hr>


        </div>
        <div class="form-group">
            <button @click="addRowJobInfo" type="button" class="btn btn-secondary"><i
                    class="fas fa-plus-circle"></i>
                Добавить
            </button>
        </div>
        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button class="btn btn-pill text-white btn-block btn-primary" @click="submitJob">Сохранить
                </button>
            </div>
        </div>
    </div>


    <FlashMessage group="job"></FlashMessage>


</template>


<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"

    export default {
        name: "JobForm",
        data() {
            return {
                startDate: '1920-01-01',
                visa_status: 0,
            }
        },
        computed: {
            ...mapState('job', ['job_form']),
        },
        methods: {
            ...mapActions('job', ['POST_JOB_DATA', 'PUT_JOB_DATA', 'GET_JOB_DATA', 'DELETE_JOB_DATA']),
            ...mapMutations('job', ['UPDATE_JOB_NAME', 'UPDATE_JOB_ADDRESS', 'UPDATE_JOB_START_YEAR', 'UPDATE_JOB_END_YEAR',
                'UPDATE_JOB_TITLE', 'UPDATE_JOB_DESCRIPTION', 'DELETE_ROW_JOB_INFO', 'ADD_ROW_JOB_INFO',
                'UPDATE_JOB_FIELD_OF_ACTIVITY', 'UPDATE_JOB_WORKING_NOW_STATUS']),


            deleteRowJobInfo(index) {
                if (this.job_form.jobInfos[index].id) {
                    this.DELETE_JOB_DATA(index);
                }
                this.DELETE_ROW_JOB_INFO(index);
            },
            addRowJobInfo() {
                this.ADD_ROW_JOB_INFO();
            },
            submitJob() {
                this.PUT_JOB_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_JOB_DATA();
                        this.$flashMessage.show({
                            group: 'job',
                            type: 'success',
                            title: 'Обновление',
                            text: 'Данные успешно обновились',
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    } else if (res.success == false) {
                        this.$flashMessage.show({
                            group: 'job',
                            type: 'error',
                            title: 'Ошибка при обновлении',
                            text: res.errors,
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    }
                })


                this.POST_JOB_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_JOB_DATA();
                        this.$flashMessage.show({
                            group: 'job',
                            type: 'success',
                            title: 'Добавление',
                            text: 'Данные успешно добавились',
                            time: 5000,
                            blockClass: 'custom-block-class'
                        })
                    } else {
                        let errorText = '';
                        console.log(res)
                        for (let err of res.errors[0]) {
                            errorText += err.message + '\n'
                        }
                        this.$flashMessage.show({
                            group: 'job',
                            type: 'error',
                            title: 'Ошибка при добавлении',
                            text: errorText,
                            time: 10000
                        })
                    }
                })

            },
            convertTimestampToDate,
            changeJobName(index, e) {
                this.UPDATE_JOB_NAME({index, value: e.target.value})
            },
            changeJobAddress(index, e) {
                this.UPDATE_JOB_ADDRESS({index, value: e.target.value})
            },

            changeJobStartYear(index, e) {
                if (new Date(e.target.value) > new Date(this.startDate)) {
                    this.UPDATE_JOB_START_YEAR({index, value: e.target.value})
                }
            },

            changeJobEndYear(index, e) {
                if (new Date(e.target.value) > new Date(this.startDate)) {
                    this.UPDATE_JOB_END_YEAR({index, value: e.target.value})
                }
            },

            changeJobTitle(index, e) {
                this.UPDATE_JOB_TITLE({index, value: e.target.value})
            },

            changeJobDescription(index, e) {
                this.UPDATE_JOB_DESCRIPTION({index, value: e.target.value})
            },
            changeFieldOfActivity(index, e) {
                this.UPDATE_JOB_FIELD_OF_ACTIVITY({index, value: e.target.value})
            },
            changeJobEndYearCheck(index, e) {
                this.UPDATE_JOB_WORKING_NOW_STATUS({index, value: e.target.checked})
            },

        },
        created() {
            this.GET_JOB_DATA()
        }
    }

</script>
<style scoped>
    .job-remove-item__button {
        color: red;
    }

    .under_job_text {
        font-size: 15px;
        color: darkgray;
    }
</style>

