<template>
  <div class="professional-certification-form mt-4">
    <h4 style="text-align: center">Профессиональная сертификация</h4>

    <div class="form-row" v-for="(professionalCertificationInfo, index) in professionalCertification_form.professionalCertificationInfos" :key="index">
      <div class="professional-certification-remove-item__button d-flex flex-row-reverse">
        <i class="fa fa-times" @click="deleteRowProfessionalCertificationInfo(index)"></i>
      </div>


      <div class="form-group row mt-4">
        <label for="certification_center_id" class="col-md-3 col-form-label">Центр сертификации</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  id="certification_center_id"  @input="changeProfessionalCertificationData(index,'certification_center_id', $event)">
            <option v-for="(item, index) in [{id: 0, name: 'Выберите центр сертификации'}, ...professionalCertification_form.certificationCenter]"
                    :value="item.id" :selected="item.id == professionalCertificationInfo.certification_center_id"
                    :key="index">{{ item.name }}
            </option>
          </select>


          <div class="mt-2" v-if="professionalCertification_form?.certificationCenter?.find(i=>i.id == professionalCertificationInfo?.certification_center_id)?.name == 'другое'">
            <input id="other_certification_center_name" type="text" class="form-control"
                   placeholder="Название центра сертификации" :value="professionalCertificationInfo.other_certification_center_name"
                   @input="changeProfessionalCertificationData(index,'other_certification_center_name', $event)">
          </div>




        </div>

      </div>

      <div class="form-group row mt-4">
        <label for="education_groups_id" class="col-md-3 col-form-label">Профессиональная область</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  id="education_groups_id" @input="changeProfessionalCertificationData(index,'education_groups_id', $event)">
            <option v-for="(item, index) in [{id: 0, name: 'Выберите профессиональную область'}, ...professionalCertification_form.educationGroups]"
                    :value="item.id" :selected="professionalCertificationInfo.education_groups_id == item.id"
                    :key="index">{{ item.code }} {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row mt-4">
        <label for="expiry_date" class="col-md-3 col-form-label">Срок действия сертификата</label>
        <div class="col-md-3" v-if="professionalCertificationInfo.is_perpetual == 0">
          <input type="date" class="form-control" @change="changeExpiryDate(index, 'expiry_date', $event)" :value="convertTimestampToDate(professionalCertificationInfo.expiry_date)">
        </div>
        <div class="col-md-3">
          <input type="checkbox" id="certificate_status"
                 :checked="1 == professionalCertificationInfo.is_perpetual"
                 @change="changeExpiryDate(index, 'is_perpetual', $event, 'checked')"
          />
          &nbsp;
          <label class="form-check-label" for="certificate_status">Бессрочный</label>
        </div>
      </div>



      <div class="form-group row mt-4">
        <label for="file" class="col-md-3 col-form-label">Файл сертификата</label>
        <div class="col-md-9">
          <div v-if="professionalCertificationInfo.file==='' ||  typeof professionalCertificationInfo.file == 'object'">
            <input class="form-control" type="file" id="file" ref="file" title=" "
                   :data-index="index"
                   :name="'file[' + index + ']'"
                   @change="handleFileUpload"/>
          </div>



          <div class="my-3" v-else>
            <a :href="'https://back.uib.kz/uploads/' + professionalCertificationInfo.file"> {{ professionalCertificationInfo.file }} </a>
          </div>
        </div>
      </div>


      <hr>

    </div>
    <div class="form-group">
      <button @click="addRowProfessionalCertificationInfo" type="button" class="btn btn-secondary"><i
          class="fas fa-plus-circle"></i>
        Добавить
      </button>
    </div>

    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded"
                :loading="loadingSave" @click="save"/>
<!--        <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submit()">-->
<!--          Сохранить-->
<!--        </button>-->
      </div>
    </div>
  </div>


<!--  <FlashMessage group="professional-certification"></FlashMessage>-->

</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/date.helpers"
export default {
  name: "ProfessionalCertificationForm",
  data() {
    return {
      loadingSave: false,
    }
  },
  computed: {
    ...mapState('professionalCertification', ['professionalCertification_form']),
  },

  methods: {
    ...mapActions('professionalCertification', ['POST_PROFESSIONAL_CERTIFICATION_DATA', 'PUT_PROFESSIONAL_CERTIFICATION_DATA',
      'GET_PROFESSIONAL_CERTIFICATION_DATA', 'DELETE_PROFESSIONAL_CERTIFICATION_DATA','GET_EDUCATION_GROUPS', 'GET_CERTIFICATION_CENTER']),
    ...mapMutations('professionalCertification', ['DELETE_ROW_PROFESSIONAL_CERTIFICATION_INFO', 'ADD_ROW_PROFESSIONAL_CERTIFICATION_INFO', 'UPDATE_PROFESSIONAL_CERTIFICATION_DATA']),

    convertTimestampToDate,

    changeProfessionalCertificationData(index, property, e) {
      let value = e.target.value

      console.log(property, 'property')

      // if (property == 'certification_center_id' && value == 21) {
      //   console.log(property, 'property property property')
      //   this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property: 'certification_center_id', value: null})
      //   //this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property, value})
      // }
      // if (this.professionalCertification_form.certificationCenter.find(i=>i.id == value).name == 'другое') {
      //
      //   this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property, value: null})
      // }
      //else {
        this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property, value})
      //}

    },
    changeExpiryDate(index, property, e, val = 'value') {
      //console.log(e.target.value, 'e.target.value')

      const value = val != 'checked' ? parseInt((new Date(e.target.value)).getTime() / 1000) : e.target[val] ? 1 : 0
      this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property, value})

      // if (property == 'expiry_date') {
      //   let value = parseInt((new Date(e.target.value)).getTime() / 1000)
      //   console.log(value, 'changeExpiryDate value')
      //
      //   this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property, value})
      // }
      // else {
      //   this.UPDATE_PROFESSIONAL_CERTIFICATION_DATA({index, property, value: 1})
      // }

      //this.UPDATE_EXPIRY_DATE({index, value})
    },

    deleteRowProfessionalCertificationInfo(index) {
      if (this.professionalCertification_form.professionalCertificationInfos[index].id) {
        this.DELETE_PROFESSIONAL_CERTIFICATION_DATA(index);
      }
      this.DELETE_ROW_PROFESSIONAL_CERTIFICATION_INFO(index);
    },
    addRowProfessionalCertificationInfo() {
      this.ADD_ROW_PROFESSIONAL_CERTIFICATION_INFO();
    },

    async save() {
      this.loadingSave = true



      if (this.professionalCertification_form.professionalCertificationInfos.filter(i=>!i.id).length > 0) {
        const postRes = await this.POST_PROFESSIONAL_CERTIFICATION_DATA()
        if (postRes) {
          await this.GET_PROFESSIONAL_CERTIFICATION_DATA()
          this.$message({title: 'Успешно сохранено'})
        }
      }
      else {
        const putRes = await this.PUT_PROFESSIONAL_CERTIFICATION_DATA()
        if (putRes) {
          await this.GET_PROFESSIONAL_CERTIFICATION_DATA()
          this.$message({title: 'Успешно обновлено'})
        }
      }


      this.loadingSave = false
    },


    handleFileUpload(e) {
      let file = e.target.files[0];
      let index = e.target.dataset.index;
      this.professionalCertification_form.professionalCertificationInfos[index].file = file;
      console.log(this.professionalCertification_form.professionalCertificationInfos[index].file, 'file ' + index)
      console.log(this.professionalCertification_form.professionalCertificationInfos)
    },
  },
  created() {
    this.GET_PROFESSIONAL_CERTIFICATION_DATA();
    this.GET_EDUCATION_GROUPS()
    this.GET_CERTIFICATION_CENTER()
  }
}
</script>

<style scoped>
.professional-certification-remove-item__button {
  color: red;
}

</style>