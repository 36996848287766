<template>
    <div class="qualification-form mt-4">
        <h4 style="text-align: center">Повышение квалификации, курсы</h4>

        <div class="form-row" v-for="(qualificationInfo, index) in qualification_form.qualificationInfos" :key="index">
            <div class="qualification-remove-item__button d-flex flex-row-reverse">
                <i class="fa fa-times" @click="deleteRowQualificationInfo(index)"></i>
            </div>


            <div class="form-group row mt-4">
                <label for="institution" class="col-md-3 col-form-label">Учебное заведение</label>
                <div class="col-md-9">
                    <input id="institution" type="text" class="form-control"
                           placeholder="Название" :value="qualificationInfo.institution"
                            disabled>
<!--                  @input="e=>{changeQualificationInstitution(index, e)}"-->

                  <select class="form-control form-select"
                          id="sp_university_id"
                          @input="e=>{changeQualificationUniversity(index, e)}">
                    <option v-for="(item, index) in [{id: 0, name: 'Выберите университет'}, ...conferenceAuthor_form.university]"
                            :value="item.id" :selected="qualificationInfo.sp_university_id == item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>

            </div>

            <div class="form-group row mt-4">
                <label for="qualification_organization" class="col-md-3 col-form-label">Проводившая организация</label>
                <div class="col-md-9">
                    <input id="qualification_organization" type="text" class="form-control"
                           placeholder="Название" :value="qualificationInfo.organization"
                           @input="e=>{changeQualificationOrganization(index, e)}">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="qualification_course_name" class="col-md-3 col-form-label">Название курса</label>
                <div class="col-md-9">
                    <input id="qualification_course_name" type="text" class="form-control"
                           placeholder="Название курса" :value="qualificationInfo.course_name"
                           @input="e=>{changeQualificationCourseName(index, e)}">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="qualification_hours_count" class="col-md-3 col-form-label">Количество часов</label>
                <div class="col-md-9">
                    <input id="qualification_hours_count" type="number" class="form-control"
                           placeholder="Количество часов" :value="qualificationInfo.hours_count"
                           @input="e=>{changeQualificationHoursCount(index, e)}">
                </div>
            </div>

            <div class="form-group row mt-4">
                <label for="qualification_specialization" class="col-md-3 col-form-label">Специализация</label>
                <div class="col-md-9">
                    <input id="qualification_specialization" type="text" class="form-control"
                           placeholder="Специализация"
                           :value="qualificationInfo.specialization"
                           @input="e=>{changeQualificationSpecialization(index, e)}">
                </div>
            </div>


            <div class="form-group row mt-4">
                <label for="qualification_end_year" class="col-md-3 col-form-label">Год окончания</label>
                <div class="col-md-9">
                    <input id="qualification_end_year" type="number" class="form-control"
                           :value="qualificationInfo.end_year" @input="e=>{changeQualificationEndYear(index, e)}">
                </div>
            </div>

          <div class="form-group row mt-4">
            <label for="certificate-series" class="col-md-3 col-form-label">Серия сертификата</label>
            <div class="col-md-9">
              <input id="certificate-series" type="text" class="form-control"
                     :value="qualificationInfo.certificate_series" @input="e=>{changeQualificationCertificateSeries(index, e)}">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="certificate-number" class="col-md-3 col-form-label">Номер сертификата</label>
            <div class="col-md-9">
              <input id="certificate-number" type="text" class="form-control"
                     :value="qualificationInfo.certificate_number" @input="e=>{changeQualificationCertificateNumber(index, e)}">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="certificate" class="col-md-3 col-form-label">Файл сертификата</label>
            <div class="col-md-9">
              <div v-if="qualificationInfo.certificate_file==='' ||  typeof qualificationInfo.certificate_file == 'object'">
                <input class="form-control" type="file" id="certificate" ref="file" title=" "
                       :data-index="index"
                       :name="'file[' + index + ']'"
                       @change="handleFileUpload"/>
              </div>



              <div class="my-3" v-else>
                <a :href="'https://back.uib.kz/uploads/' + qualificationInfo.certificate_file"> {{ qualificationInfo.certificate_file }} </a>
              </div>
            </div>
          </div>


            <hr>

        </div>
        <div class="form-group">
            <button @click="addRowQualificationInfo" type="button" class="btn btn-secondary"><i
                    class="fas fa-plus-circle"></i>
                Добавить
            </button>
        </div>

        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitQualification()">
                    Сохранить
                </button>
            </div>
        </div>
    </div>


    <FlashMessage group="qualification"></FlashMessage>

</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "QualificationForm",
        computed: {
            ...mapState('qualification', ['qualification_form']),
          ...mapState('conferenceAuthor', ['conferenceAuthor_form']),
        },

        methods: {
            ...mapActions('qualification', ['POST_QUALIFICATION_DATA', 'PUT_QUALIFICATION_DATA', 'GET_QUALIFICATION_DATA', 'DELETE_QUALIFICATION_DATA']),
            ...mapMutations('qualification', ['UPDATE_QUALIFICATION_INSTITUTION', 'UPDATE_QUALIFICATION_ORGANIZATION', 'UPDATE_QUALIFICATION_SPECIALIZATION',
                'UPDATE_QUALIFICATION_END_YEAR', 'UPDATE_QUALIFICATION_COURSE_NAME', 'UPDATE_QUALIFICATION_HOURS_COUNT',
                'DELETE_ROW_QUALIFICATION_INFO', 'ADD_ROW_QUALIFICATION_INFO', 'UPDATE_QUALIFICATION_UNIVERSITY', 'UPDATE_QUALIFICATION_CERTIFICATE_SERIES', 'UPDATE_QUALIFICATION_CERTIFICATE_NUMBER']),

          ...mapActions('conferenceAuthor', ['GET_SP_UNIVERSITY']),

            deleteRowQualificationInfo(index) {
                if (this.qualification_form.qualificationInfos[index].id) {
                    this.DELETE_QUALIFICATION_DATA(index);
                }
                this.DELETE_ROW_QUALIFICATION_INFO(index);
            },
            addRowQualificationInfo() {
                this.ADD_ROW_QUALIFICATION_INFO();
            },
            submitQualification() {
                //this.POST_QUALIFICATION_DATA();

                this.PUT_QUALIFICATION_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_QUALIFICATION_DATA();
                        this.$flashMessage.show({
                            group: 'qualification',
                            type: 'success',
                            title: 'Обновление',
                            text: 'Данные успешно обновились',
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    } else if (res.success == false) {
                        this.$flashMessage.show({
                            group: 'qualification',
                            type: 'error',
                            title: 'Ошибка при обновлении',
                            text: res.errors,
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    }
                })

                this.POST_QUALIFICATION_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_QUALIFICATION_DATA();
                        this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
                    } else {
                        const errorText = res.errors.success[0].map(err => err.message).join('\n');
                        this.$error({title: 'Добавление', text: errorText})
                    }
                })
            },

          changeQualificationCertificateSeries(index, e) {
            this.UPDATE_QUALIFICATION_CERTIFICATE_SERIES({index, value: e.target.value})
          },
          changeQualificationCertificateNumber(index, e) {
            this.UPDATE_QUALIFICATION_CERTIFICATE_NUMBER({index, value: e.target.value})
          },
          changeQualificationUniversity(index, e) {
            this.UPDATE_QUALIFICATION_UNIVERSITY({index, value: e.target.value})
          },
            changeQualificationInstitution(index, e) {
                this.UPDATE_QUALIFICATION_INSTITUTION({index, value: e.target.value})
            },
            changeQualificationOrganization(index, e) {
                this.UPDATE_QUALIFICATION_ORGANIZATION({index, value: e.target.value})
            },
            changeQualificationSpecialization(index, e) {
                this.UPDATE_QUALIFICATION_SPECIALIZATION({index, value: e.target.value})
            },
            changeQualificationEndYear(index, e) {
                this.UPDATE_QUALIFICATION_END_YEAR({index, value: e.target.value})
            },
            changeQualificationCourseName(index, e) {
                this.UPDATE_QUALIFICATION_COURSE_NAME({index, value: e.target.value})
            },
            changeQualificationHoursCount(index, e) {
                this.UPDATE_QUALIFICATION_HOURS_COUNT({index, value: e.target.value})
            },


          handleFileUpload(e) {
            let file = e.target.files[0];
            let index = e.target.dataset.index;
            console.log(e.target.dataset.index);
            console.log(e.target.name, 'name');
            this.qualification_form.qualificationInfos[index].file = file;
            console.log(this.qualification_form.qualificationInfos[index].file, 'file ' + index)
          },
        },
        created() {
            this.GET_QUALIFICATION_DATA();
          this.GET_SP_UNIVERSITY()
        }
    }
</script>

<style scoped>
    .qualification-remove-item__button {
        color: red;
    }

</style>
