<template>

  <div class="mt-4">

    <div class="row">
      <div class="col-10">
        Публикации
      </div>
      <div class="col-2">
        <label for="allArticles">Выбрать все</label>
        <br>
        <input class="big-checkbox" type="checkbox" id="allArticles" v-model="checkedAll">

      </div>
    </div>
    <hr/>


    <div class="container text-center" v-if="loading">
      <p>Загрузка публикации</p>
      <div class="loader "></div>
    </div>

    <div>
      <nav class="d-flex justify-content-center" aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left" aria-hidden="true"></i></a></li>
          <li class="page-item" v-for="item in pageCount" :key="item">
            <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
          </li>
          <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right" aria-hidden="true"></i></a></li>
        </ul>
      </nav>
    </div>

    <div class="row" v-for="(item) in paginated" :key="item['source-id']">

      <div class="card">
        <div class="card-body row">
          <div class="col-11">
            <div class="card-title mb-2">
              <strong>
                <a v-bind:href=" item.link[2]['@href'] " target="_blank" class="text-decoration-none">
                  {{ item['dc:title'] }}
                </a>
              </strong>
            </div>
            <div class="mb-1">Publisher: {{ item['prism:publicationName'] }}</div>
            <p class="card-text">
              <span class="bg-success text-light me-2 border-2 p-1"> {{ item['subtypeDescription'] }}</span>
              <span class="me-2">{{ item['prism:coverDisplayDate'] }}</span>
              <span class="me-2">DOI: {{ item['prism:doi'] }}</span>
              <span class="me-2">ISSN: {{ item['prism:issn'] }}</span>
            </p>
            <p class="card-text">{{ item['authors'] }}</p>
          </div>
          <div class="col-1">
            <input type="checkbox" class="big-checkbox" :checked="item.isCheck"
                   @change="e=>{changeCheckArticle(item['source-id'], e)}">
          </div>
        </div>
      </div>

    </div>

    <div>
      <nav class="d-flex justify-content-center" aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left" aria-hidden="true"></i></a></li>
          <li class="page-item" v-for="item in pageCount" :key="item">
            <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
          </li>
          <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right" aria-hidden="true"></i></a></li>
        </ul>
      </nav>
    </div>

    <div class="text-center">
      <button class="btn btn-pill text-white btn-block btn-primary mt-4"
              v-on:click="submitArticles">Сохранить
      </button>
    </div>


  </div>


</template>
<script>

import {mapGetters, mapActions, mapState, mapMutations} from "vuex";

export default {
  name: 'ResearchWorksChoose',
  data() {
    return {
      current: 1,
      pageSize: 5
    }
  },
  computed: {
    ...mapState('researchwork', ['scopusData', 'loading']),
    ...mapGetters('researchwork', ["getFirstName", "getLastName"]),
    checkedAll: {
      get() {
        return this.scopusData.isCheckedAll
      },
      set(value) {
        this.CHECKED_ALL(value)
      }
    },
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.scopusData.authorArticles.slice(this.indexStart, this.indexEnd);
    },
    pageCount() {
      let pages = []
      for (let i = 1; i <= Math.ceil(this.scopusData.authorArticles.length / this.pageSize); i++) {
        pages.push(i)
      }
      return pages
    }


  },
  methods: {
    ...mapActions('researchwork', ["GET_SCOPUS", "GET_SCOPUS_AUTHOR", "POST_AUTHOR_ARTICLES", "GET_DB_AUTHOR_ARTICLES"]),
    ...mapMutations('researchwork', ["CHECKED_ALL", "UPDATE_CHECK_ARTICLE"]),

    prev() {
      if (this.current > 1) {
        this.current--;
      }
    },
    next() {
      if (this.current < this.scopusData.authorArticles.length / this.pageSize) {
        this.current++;
      }
    },
    movePageNumber(page) {
      this.current = page
    },
    async submitArticles() {

      let postData = await this.POST_AUTHOR_ARTICLES()
      if (postData) {
        await this.GET_DB_AUTHOR_ARTICLES()
        this.$message({text: 'Сохранено'})
      }



    },
    changeCheckArticle(source_id, e) {
      console.log(source_id, ', ', e.target.checked)
      this.UPDATE_CHECK_ARTICLE({source_id, value: e.target.checked})
    },

  },
  created() {

  },
  mounted() {
  }
}

</script>

<style scoped>
.big-checkbox {
  width: 20px;
  height: 20px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>