<template>

  <div class="row">
    <nav>
      <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-foreigneducationalgrant-tab" data-bs-toggle="tab" data-bs-target="#nav-foreigneducationalgrant"
                type="button" role="tab" aria-controls="nav-foreigneducationalgrant" aria-selected="true"><i class="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp; Зарубежные образовательные гранты
        </button>
        <button class="nav-link" id="nav-researchgrant-tab" data-bs-toggle="tab" data-bs-target="#nav-researchgrant"
                type="button" role="tab" aria-controls="nav-researchgrant" aria-selected="false"><i class="fa fa-line-chart" aria-hidden="true"></i>&nbsp; Научно-исследовательские гранты
        </button>
      </div>
    </nav>

    <div class="form-block">

      <div class="tab-content" id="nav-tabContent">

        <div class="tab-pane fade show active" id="nav-foreigneducationalgrant" role="tabpanel"
             aria-labelledby="nav-foreigneducationalgrant-tab">
          <ForeignEducationalGrantsForm/>
        </div>

        <div class="tab-pane fade" id="nav-researchgrant" role="tabpanel"
             aria-labelledby="nav-researchgrant-tab">
          <ResearchGrantsForm/>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import ForeignEducationalGrantsForm from '@/components/user-personal/teacher_grants/ForeignEducationalGrantsForm.vue'
import ResearchGrantsForm from '@/components/user-personal/teacher_grants/ResearchGrantsForm.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Education',
  components: {
    ForeignEducationalGrantsForm,
    ResearchGrantsForm,
  },
  methods: {

  },
  computed: {

  },
  created() {

  }
}
</script>