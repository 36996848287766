<template>

  <div class="photo-form mt-4 text-center">
<!--    <h4 style="text-align: center">Фотография</h4>-->
<!--    &lt;!&ndash;    <div class="text-center mt-4">&ndash;&gt;-->
<!--    &lt;!&ndash;      <Avatar :image="'data:image/png;base64,'+photo" size="xlarge" shape="circle"/>&ndash;&gt;-->
<!--    &lt;!&ndash;      &lt;!&ndash; <img :src="'data:image/png;base64,'+item.photo" class="mr-2" size="xlarge" shape="circle"/> &ndash;&gt;&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <p>Загрузите фото в формате png, jpg, jpeg для ППС!!!</p>-->
<!--    <div class="text-center mb-4">-->

      <img v-if="beauty_photo" width="100" class="rounded-3 mb-3" style="width: 225px; height: 300px" :src="'https://back.uib.kz/uploads/'+ beauty_photo"/>

<!--      <div class="mt-2">-->
<!--        <input type="file" accept="image/png, image/jpg, image/jpeg" class="form-control"-->
<!--               @change="handleFileUpload($event)">-->

<!--        <Button   icon="pi pi-save" label="Сохранить" class="mt-2 p-button-rounded"-->
<!--                :loading="loadingSave" @click="save"/>-->
<!--        &lt;!&ndash;      v-if="photo == null v-if="Object.keys(ppsDataPhoto).length > 0""&ndash;&gt;-->
<!--      </div>-->

<!--    </div>-->


<!--    <avatar/>-->


  </div>

</template>

<script>
import axios from "axios";
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import Avatar from '../Avatar.vue'

export default {
  name: 'PhotoForm',
  data: () => ({
    ppsDataPhoto: {},
    loadingSave: false
  }),
  components: {
    // Avatar
  },
  computed: {
    ...mapState('personal', ['form', 'genders', 'headHunterData']),

    beauty_photo: {
      get() {
        return this.form.beauty_photo
      },
    },
  },
  methods: {
    ...mapActions('personal', ['POST_PPS_DATA_PHOTO']),
    ...mapActions(['GET_PPS_DATA']),
    handleFileUpload(e) {
      let file = e.target.files[0];
      this.ppsDataPhoto['file'] = file

      console.log(this.ppsDataPhoto, 'ppsDataPhoto')
      //this.SET_STUDENT_SUMMER_COURSE_FILE(file)
    },

    async save() {
      await this.POST_PPS_DATA_PHOTO(this.ppsDataPhoto)
      await this.GET_PPS_DATA()
    }

  },
  async created() {

    //await this.getTest();
  },
  mounted() {
  }


}

</script>

<style scoped>

</style>
