<template>

    <div class="col-md-12 mt-4">
        <h4 style="text-align: center">Поиск автора</h4>
        <div class="form-group row mb-4">
            <div class="col-md-4">
                <input type="text" class="form-control" placeholder="Фамилия Автора"  v-model="author_lastname">
            </div>
            <div class="col-md-4">
                <input type="text" class="form-control" placeholder="Имя Автора" v-model="author_firstname">
            </div>
            <div class="col-md-4">
                <button class="btn btn-pill text-white btn-block btn-primary" @click="searchResearchWorks">Поиск
                </button>
            </div>
        </div>

    </div>

    <div v-if="scopusData.isMoreTwentyAuthors" class="row g-2">
        <div class="col-auto">
            <input type="text" class="form-control" placeholder="ID автора" v-model="author_id">
        </div>
        <div class="col-auto">
            <button type="button" class="btn btn-primary mb-3" @click="selectAuthorId">Выбрать</button>
        </div>
    </div>


    <div>
        <div>
            <nav class="d-flex justify-content-center" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#" @click="prev">Предыдущие авторы</a></li>
                    <li class="page-item" v-for="item in pageCount" :key="item">
                        <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#" @click="next()">Следующие авторы</a></li>
                </ul>
            </nav>
        </div>
        <div>
            <div class="row mt-4">
              <div class="col-md-1">
                Выбрать
              </div>
                <div class="col-md-1">
                    №
                </div>
                <div class="col-md-1">
                    ФИО
                </div>
                <div class="col-md-2">
                    Scopus ID
                </div>
                <div class="col-md-3">
                    Организация
                </div>
                <div class="col-md-2">
                    Страна
                </div>
                <div class="col-md-2">
                    Город
                </div>

            </div>
            <hr>
            <div class="row" v-for="(item,index) in paginated" :key="item.author_id">
              <div class="col-md-1">
                <input class="form-check-input" name="checkAuthor" type="radio" :value="item.author_id"
                       @input="clickAuthor">
              </div>
                <div class="col-md-1">
                    {{ indexStart + ++index }}
                </div>
                <div class="col-md-1">
                    {{ author_lastname }}, {{ author_firstname }}
                </div>
                <div class="col-md-2">
                    {{ item.author_id }}
                </div>
                <div class="col-md-3">
                    {{ item.affilationData['affiliation-name'] }}
                </div>
                <div class="col-md-2">
                    {{ item.affilationData['affiliation-country'] }}
                </div>
                <div class="col-md-2">
                    {{ item.affilationData['affiliation-city'] }}
                </div>

                <hr>

            </div>
        </div>

        <div>
            <nav class="d-flex justify-content-center" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#" @click="prev">Предыдущие авторы</a></li>
                    <li class="page-item" v-for="item in pageCount" :key="item">
                        <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#" @click="next()">Следующие авторы</a></li>
                </ul>
            </nav>
        </div>
    </div>


</template>
<script>
    import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
    import {getCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        name: 'AuthorChoose',

        data() {
            return {
                author_lastname: getCookie('LAST_NAME') ? getCookie('LAST_NAME') : '',
                author_firstname: getCookie('FIRST_NAME') ? getCookie('FIRST_NAME') : '',
                current: 1,
                pageSize: 10
            }
        },
        computed: {
            ...mapState('researchwork', ['scopusData', 'loading', 'name']),
            //...mapGetters('researchwork', ["getFirstName", "getLastName"]),
            author_id: {
                set(value) {
                    console.log(value, 'check author id', typeof value == 'string')
                    this.SET_AUTHOR_ID(value)
                },
                get() {
                    return this.scopusData.author_id
                }
            },
            indexStart() {
                return (this.current - 1) * this.pageSize;
            },
            indexEnd() {
                return this.indexStart + this.pageSize;
            },
            paginated() {
                return this.scopusData.authorData.slice(this.indexStart, this.indexEnd);
            },
            pageCount() {
                let pages = []
                for (let i = 1; i <= Math.round(this.scopusData.authorData.length / this.pageSize); i++) {
                    pages.push(i)
                }
                return pages
            }
        },
        methods: {
            ...mapActions('researchwork', ["GET_SCOPUS", "GET_SCOPUS_AUTHOR", "POST_AUTHOR_ARTICLES"]),
            ...mapMutations('researchwork', ["SET_AUTHOR_ID"]),
            prev() {
                if (this.current > 1) {
                    this.current--;
                }
            },
            next() {
                if (this.current < this.scopusData.authorData.length / this.pageSize) {
                    this.current++;
                }
            },
            movePageNumber(page) {
                this.current = page
            },
            searchResearchWorks() {

                this.GET_SCOPUS_AUTHOR({
                    "firstname": this.author_firstname,
                    "lastname": this.author_lastname
                })
            },
            clickAuthor(e) {
                console.log(e.target.value)
                this.SET_AUTHOR_ID(e.target.value)
                this.GET_SCOPUS();

            },
            selectAuthorId() {
                this.GET_SCOPUS_AUTHOR({
                    isAuthorId: true
                })
            }

        },
        created() {
            //this.GET_SCOPUS_AUTHOR()
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>