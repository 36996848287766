<template>

  <div class="my-3">
    <h4 class="text-center">Зарубежные образовательные гранты</h4>

    <div class="my-3">
      <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary m-1"
              @click="addForeignEducationalGrants"/>
    </div>

    <div class="row border rounded-3 px-1 py-3 my-2"
         v-for="(foreignEducationalGrant, index) in teacherForeignEducationalGrants"
         :key="index">

      <div class="col-md-10">
        <h5>{{ foreignEducationalGrant.training_program_name }}</h5>
        <div class="my-1">
          {{foreignEducationalGrant.organizer_name}}, {{foreignEducationalGrant.organization_name}}
        </div>
        <div class="my-1">{{ foreignEducationalGrant.start_date }} - {{ foreignEducationalGrant.end_date }}</div>
        <div>
          <span v-if="foreignEducationalGrant.sp_teacher_grant_type_id"
                class="bg-primary text-light rounded-3 px-2 py-1 me-2">
            {{ grantsTypes.find(i=>i.id==foreignEducationalGrant.sp_teacher_grant_type_id)?.name }}
          </span>
          <span v-if="foreignEducationalGrant.study_level_id" class="bg-secondary text-light rounded-3 px-2 py-1">
            {{ studyLevels.find(i=>i.id==foreignEducationalGrant.study_level_id)?.name }}
          </span>
        </div>
      </div>
      <div class="col-md-1">
        <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                @click="editForeignEducationalGrant(index)"/>
      </div>
      <div class="col-md-1">
        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                @click="deleteForeignEducationalGrant(foreignEducationalGrant.id)"/>
      </div>

    </div>


    <!-- add or update grant modal -->
    <Dialog :header="foreignGrant?.id?'Обновление гранта':'Добавление гранта'"
            v-model:visible="displayForeignGrant"
            :style="{width: '98%', maxWidth: '1000px'}" :modal="true" :closable="false">
      <div class="form-row my-3">
        <div class="form-group row mt-3">
          <label for="organizer_name" class="col-md-3 col-form-label">Имя организатора</label>
          <div class="col-md-9">
            <input id="organizer_name" type="text" class="form-control"
                   placeholder="Имя организатора" v-model="foreignGrant.organizer_name">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="training_program_name" class="col-md-3 col-form-label">Название программы обучения</label>
          <div class="col-md-9">
            <input id="training_program_name" type="text" class="form-control"
                   placeholder="Название программы обучения" v-model="foreignGrant.training_program_name">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="sp_teacher_grant_type_id" class="col-md-3">Тип гранта</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="sp_teacher_grant_type_id"
                    v-model="foreignGrant.sp_teacher_grant_type_id">
              <option v-for="(item, index) in grantsTypes" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="study_level_id" class="col-md-3">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" id="study_level_id"
                    v-model="foreignGrant.study_level_id">
              <option v-for="(item, index) in studyLevels" :value="item.id" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="start_date" class="col-md-3 col-form-label">Дата начала</label>
          <div class="col-md-9">
            <input id="start_date" type="text" class="form-control" placeholder="2021"
                   v-model="foreignGrant.start_date">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="end_date" class="col-md-3 col-form-label">Дата окончания</label>
          <div class="col-md-9">
            <input id="end_date" type="text" class="form-control" placeholder="2022"
                   v-model="foreignGrant.end_date">
          </div>
        </div>

        <div class="form-group row mt-3">
          <label for="organization_name" class="col-md-3 col-form-label">Название организации</label>
          <div class="col-md-9">
            <input id="organization_name" type="text" class="form-control" placeholder="Название организации"
                   v-model="foreignGrant.organization_name">
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeForeignGrantModal" class="p-button-text"/>
        <Button :label="foreignGrant?.id?'Обновить':'Создать'" icon="pi pi-check" autofocus
                @click="saveForeignEducationalGrants"/>
      </template>
    </Dialog>
    <!-- end add or update grant modal -->
  </div>
</template>

<script>
  import {mapActions, mapState, mapMutations} from 'vuex'

  export default {
    name: "ForeignEducationalGrantsForm",
    data() {
      return {
        teacherForeignEducationalGrants: [],
        foreignGrant: {},
        displayForeignGrant: false,
        grantsTypes: [],
        loading: true
      }
    },
    methods: {
      ...mapActions('teacherGrants', ['GET_TEACHER_GRANT_TYPE',
        'POST_FOREIGN_EDUCATIONAL_GRANTS', 'PUT_FOREIGN_EDUCATIONAL_GRANTS',
        'GET_FOREIGN_EDUCATIONAL_GRANTS', 'DELETE_FOREIGN_EDUCATIONAL_GRANTS']),
      addForeignEducationalGrants() {
        this.foreignGrant = {
          organizer_name: '',
          training_program_name: '',
          sp_teacher_grant_type_id: null,
          study_level_id: null,
          start_date: null,
          end_date: null,
          organization_name: '',
        }
        this.displayForeignGrant = true
      },
      async deleteForeignEducationalGrant(id) {
        let res = await this.DELETE_FOREIGN_EDUCATIONAL_GRANTS(id)
        if (res) {
          this.teacherForeignEducationalGrants = await this.GET_FOREIGN_EDUCATIONAL_GRANTS()
          this.$message({title: 'Успешно удалено'})
        }
      },
      async saveForeignEducationalGrants() {
        let res = false
        if (this.foreignGrant.id) {
          res = await this.PUT_FOREIGN_EDUCATIONAL_GRANTS(this.foreignGrant)
        } else {
          res = await this.POST_FOREIGN_EDUCATIONAL_GRANTS(this.foreignGrant)
        }
        if (res) {
          this.teacherForeignEducationalGrants = await this.GET_FOREIGN_EDUCATIONAL_GRANTS()
          this.closeForeignGrantModal()
          this.$message({title: 'Успешно сохранено'})
        }
        console.log(this.foreignGrant)
      },
      editForeignEducationalGrant(index) {
        this.foreignGrant = this.teacherForeignEducationalGrants[index]
        this.displayForeignGrant = true
      },
      closeForeignGrantModal() {
        this.displayForeignGrant = false
      }
    },
    computed: {
      ...mapState('educationdiscipline', ['educationdiscipline_form']),
      studyLevels() {
        return this.educationdiscipline_form.studyLevel
      }
    },
    async mounted() {
      this.grantsTypes = await this.GET_TEACHER_GRANT_TYPE()
      this.teacherForeignEducationalGrants = await this.GET_FOREIGN_EDUCATIONAL_GRANTS()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>