<template>

    <div class="document-form mt-4">
        <h4 style="text-align: center">Документ</h4>
        <div class="form-group row mb-4">
            <label for="citizenship" class="col-md-3 col-form-label">Гражданство</label>
            <div class="col-md-9">
                <select class="form-control form-select" id="citizenship" v-model="citizenship">
                    <option v-for="(item, index) in getAllCitizenships"
                            :value="item.id" :key="index">{{ item.name_ru }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row mb-4">
            <label for="doc_type" class="col-md-3 col-form-label">Тип документа</label>
            <div class="col-md-9">
                <select class="form-control form-select" id="doc_type" v-model="doc_type">
                    <option v-for="(item, index) in getAllDocTypes"
                            :value="item.id" :key="index">{{ item.name_ru }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row mb-4">
            <label class="col-md-3 col-form-label">Номер документа</label>
            <div class="col-md-9">
                <input type="text" class="form-control" placeholder="Номер документа" v-model="doc_number">
            </div>
        </div>

        <div class="form-group row mb-4" v-if="citizenship === 1">
            <label class="col-md-3 col-form-label">ИИН</label>
            <div class="col-md-9">
                <input type="text" class="form-control" placeholder="ИИН" v-model="doc_iin">
            </div>
        </div>

        <div class="form-group row mb-4">
            <label for="doc_issued" class="col-md-3 col-form-label">Орган выдачи</label>
            <div class="col-md-9">
                <input type="text" id="doc_issued" class="form-control" placeholder="Орган выдачи" v-model="doc_issued">
            </div>

        </div>

        <div class="form-group row mb-4">
            <label class="col-md-3 col-form-label">Дата выдачи документа</label>
            <div class="col-md-9">
                <input type="date" class="form-control" placeholder="Дата выдачи документа" v-model="doc_date_start">
            </div>
        </div>

        <div class="form-group row mb-4">
            <label class="col-md-3 col-form-label">Срок действия документа</label>
            <div class="col-md-9">
                <input type="date" class="form-control" placeholder="Срок действия документа" v-model="doc_date_end">
            </div>
        </div>

        <div class="form-group row mb-4" v-if="citizenship === 2">
            <label class="col-md-3 col-form-label">Имеется ли виза?</label>
            <div class="col-md-9">
                <input type="checkbox" id="visa_status" v-model="visa_status"/>
            </div>
        </div>

        <div class="form-group row mb-4" v-if="visa_status == true">
            <label class="col-md-3 col-form-label">Срок действия визы</label>
            <div class="col-md-9">
                <input type="date" class="form-control" placeholder="Срок действия визы" v-model="doc_visa">
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button class="btn btn-pill text-white btn-block btn-primary" @click="sendPpsDatas">Сохранить</button>
            </div>
        </div>

    </div>

    <FlashMessage group="pps"></FlashMessage>

</template>

<script>

    import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"

    export default {
        name: 'DocumentForm',
        data() {
            return {
                startDate: '1920-01-01',
                visa_status: 0,
            }
        },
        computed: {
            ...mapGetters('document', ['allCitizenships', 'allDocTypes', 'allDocIssuedes']),
            ...mapState('document', ['document_form']),
            ...mapState('personal', ['form']),
            getAllCitizenships(){
                return [{id: 0, name_ru: 'Выберите гражданство'}, ...this.allCitizenships]
            },
            getAllDocTypes(){
                console.log('allDocTypes', this.allDocTypes)
                // [{id: 0, name_ru: 'Выберите тип документа'}, ...allDocTypes]
                return this.allDocTypes
            },
            citizenship: {
                get() {
                    return this.document_form.citizenship
                },
                set(value) {
                    this.updateCitizenship(value)
                }
            },
            doc_type: {
                get() {
                    return this.document_form.doc_type
                },
                set(value) {
                    this.updateDocType(value)
                }
            },
            doc_number: {
                get() {
                    return this.document_form.doc_number
                },
                set(value) {
                    this.updateDocNumber(value)
                }
            },
            doc_iin: {
                get() {
                    return this.document_form.doc_iin
                },
                set(value) {
                    this.updateDocIin(value)
                }
            },
            doc_issued: {
                get() {
                    return this.document_form.doc_issued
                },
                set(value) {
                    this.updateDocIssuede(value)
                }
            },
            doc_date_start: {
                get() {
                    return this.convertTimestampToDate(this.document_form.doc_date_start)
                },
                set(value) {
                    if (new Date(value) > new Date(this.startDate)) {
                        this.updateDocDateStart(value)
                    }
                }
            },
            doc_date_end: {
                get() {
                    return this.convertTimestampToDate(this.document_form.doc_date_end)
                },
                set(value) {
                    if (new Date(value) > new Date(this.startDate)) {
                        this.updateDocDateEnd(value)
                    }
                }
            },
            doc_visa: {
                get() {
                    return this.convertTimestampToDate(this.document_form.doc_visa)
                },
                set(value) {
                    if (new Date(value) > new Date(this.startDate)) {
                        this.updateDocVisa(value)
                    }
                }
            },

        },
        methods: {
            ...mapActions('document', ["getCitizenships", "getDocTypes"]),
            // ...mapActions('useraddress',['POST_USER_ADDRESS_DATA', 'PUT_USER_ADDRESS_DATA']),
            ...mapActions(['POST_PPS_DATA', 'PUT_PPS_DATA', 'GET_PPS_DATA']),
            ...mapMutations('document', ['updateCitizenships', 'updateDocTypes', 'updateDocNumber',
                'updateDocIin', 'updateDocDateStart', 'updateDocDateEnd', 'updateDocVisa', 'updateDocType', 'updateCitizenship', 'updateDocIssuede']),
            convertTimestampToDate,
            sendPpsDatas() {
                // if (this.form.status === 0) {
                //   this.POST_PPS_DATA()
                //   this.POST_USER_ADDRESS_DATA()
                //
                // } else {
                //   this.PUT_PPS_DATA()
                //   this.PUT_USER_ADDRESS_DATA()
                // }

                this.PUT_PPS_DATA().then(res => {
                    this.GET_PPS_DATA();
                    if (res.success == true) {
                        this.$flashMessage.show({
                            group: 'pps',
                            type: 'success',
                            title: 'Обновление',
                            text: 'Личные данные успешно обновились',
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    } else if (res.success == false) {
                        this.$flashMessage.show({
                            group: 'pps',
                            type: 'error',
                            title: 'Ошибка при обновлении',
                            text: res.errors,
                            time: 3000,
                            blockClass: 'custom-block-class'
                        })
                    }
                })


                this.POST_PPS_DATA().then(res => {
                    this.GET_PPS_DATA();
                    if (res.success === true) {
                        this.$flashMessage.show({
                            group: 'pps',
                            type: 'success',
                            title: 'Добавление',
                            text: 'Личные данные успешно добавились',
                            time: 5000,
                            blockClass: 'custom-block-class'
                        })
                    } else {
                        console.log(res.errors[0], 'errors');
                        let errorText = '';
                        for (let err of res.errors[0]) {
                            errorText += err.message + '\n'
                        }
                        this.$flashMessage.show({
                            group: 'pps',
                            type: 'error',
                            title: 'Ошибка при добавлении',
                            text: errorText,
                            time: 10000
                        })
                    }
                })


            },
        },
        created() {
            this.getCitizenships();
            this.getDocTypes();
        },
        mounted() {
        }
    }

</script>

<style scoped>

</style>